import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";

Sentry.init({
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  dsn: import.meta.env.VITE_SENTRY_DSN_URL,
  tracesSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
