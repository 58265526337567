import { SxProps } from "@mui/material";
import { ValueToColorMapping } from "../../../components/ColoredChip";
import { UnifiedStatus } from "../api";

export const memberQuestionSxProps: SxProps = {
  paddingLeft: 2,
  paddingRight: 2,
  backgroundColor: "#AEECE3",
  borderRadius: 3,
  marginTop: 2,
  marginRight: "20%",
};

export const baseAnswerSxProps: SxProps = {
  paddingLeft: 2,
  paddingRight: 2,
  backgroundColor: "secondary.light",
  borderRadius: 3,
};

export const lawyerAnswerSxProps: SxProps = {
  ...baseAnswerSxProps,
  paddingLeft: 2,
  paddingRight: 2,
  backgroundColor: "secondary.light",
  borderRadius: 3,
  marginLeft: "20%",
  marginTop: 2,
  width: "80%",
};

export const detailColorMapping: ValueToColorMapping<UnifiedStatus> = {
  assignment_rejected: "warning",
  pending_qualification: "warning",
  awaiting_manual_check: "warning",
  archived: "error",
  answer_validated: "success",
  answered: "warning",
  additional_request_answered: "success",
  reviewed: "success",
};
