import React from "react";
import {
  Datagrid,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  useTranslate,
  WithListContext,
} from "react-admin";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Assignation } from "../../api";
import DateTimeField from "../../../../components/DateTimeField";
import ColoredChip from "../../../../components/ColoredChip";

export default function AssignationsCard(): React.ReactElement | null {
  const translate = useTranslate();
  return (
    <ReferenceManyField reference="staff/question-assignations" target="question_id">
      <WithListContext
        render={({ total }) => {
          if (total === undefined || total === 0) return null;
          return (
            <Card>
              <CardHeader title={translate("aside.assignations.title")} titleTypographyProps={{ variant: "h6" }} />
              <CardContent>
                <Datagrid bulkActionButtons={false} rowClick={false} hover={false}>
                  <DateTimeField
                    showTime
                    source="created_at"
                    label="question.edit.assignation.column.date.title"
                    sortable={false}
                  />
                  <ReferenceField
                    source="lawyer_id"
                    reference="staff/lawyers"
                    label="question.edit.assignation.column.lawyer.title"
                    sortable={false}
                  />
                  <FunctionField<Assignation>
                    label="question.edit.assignation.column.status.title"
                    render={(assignation) => (
                      <ColoredChip
                        value={assignation.status}
                        labelPrefix="question.assignation.status"
                        defaultColor="error"
                        size="small"
                        colorMapping={{ accepted: "success", pending: "warning" }}
                      />
                    )}
                  />
                </Datagrid>
              </CardContent>
            </Card>
          );
        }}
      />
    </ReferenceManyField>
  );
}
