import { Button, Confirm, useDataProvider, useNotify, useRecordContext, useTranslate } from "react-admin";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { LockReset } from "@mui/icons-material";
import { Member } from "./api";
import { EkieDataProvider } from "../../providers/dataProvider";

export default function ResetPasswordButton() {
  const notify = useNotify();
  const record = useRecordContext<Member>();
  const translate = useTranslate();
  const dataProvider = useDataProvider<EkieDataProvider>();
  const { mutate, isPending } = useMutation({
    mutationFn: () => dataProvider.resetMemberPassword(record!.id),
    onSuccess: () => notify(translate("member.reset_password.success", { type: "success" })),
    onError: (error: Error) => notify(error.message, { type: "error" }),
  });
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  if (!record) {
    return null;
  }

  return (
    <>
      <Button label="member.reset_password" onClick={handleClick} color="secondary" variant="outlined" size="medium">
        <LockReset />
      </Button>
      <Confirm
        isOpen={open}
        loading={isPending}
        title="member.reset_password.confirmTitle"
        content="member.reset_password.confirmContent"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
}
