import { alpha, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { ShowDialog } from "@react-admin/ra-form-layout";
import { RecordContextProvider, SimpleShowLayout, useGetList, useRecordContext, useTranslate } from "react-admin";
import React, { ReactNode, useState } from "react";
import { MarkdownField } from "@react-admin/ra-markdown";
import { grey } from "@mui/material/colors";
import DifferenceIcon from "@mui/icons-material/Difference";
import DateTimeField from "../../../../components/DateTimeField";
import { baseAnswerSxProps } from "../themes";
import { AiSuggestionAnswer, Answer } from "../../api";
import LawyerAnswerDateTimeBlock from "./LawyerAnswerDateTimeBlock";

export default function AiSuggestionAnswerButtonDialog(): ReactNode | null {
  const answer = useRecordContext<Answer>();
  const { data: aiSuggestionAnswers } = useGetList<AiSuggestionAnswer>("staff/ai-suggestion-answers", {
    filter: { question_id: answer?.question_id },
  });
  const translate = useTranslate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const theme = useTheme();

  if (!answer || !aiSuggestionAnswers || aiSuggestionAnswers.length === 0) return null;

  return (
    <>
      <Tooltip title={translate("question.edit.aiSuggestionAnswer.button.tooltip")}>
        <IconButton size="small" onClick={() => setIsDialogOpen(true)}>
          <DifferenceIcon fontSize="small" color="secondary" />
        </IconButton>
      </Tooltip>
      <ShowDialog
        title={translate("question.edit.aiSuggestionAnswer.dialog.title", { questionId: answer.question_id })}
        isOpen={isDialogOpen}
        close={() => setIsDialogOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <SimpleShowLayout>
          <Stack direction="row" spacing={2}>
            <Stack direction="column" width="50%">
              <RecordContextProvider value={aiSuggestionAnswers[0]}>
                <MarkdownField
                  source="content"
                  sx={{
                    ...baseAnswerSxProps,
                    backgroundColor: alpha(theme.palette.secondary.light, 0.5),
                  }}
                  label=""
                />
                <Typography variant="body2" sx={{ color: grey[600] }} component="div">
                  <Stack direction="row" spacing={0.5} justifyContent="flex-end">
                    <span>{translate("question.edit.aiSuggestionAnswer.generatedAt.label")}</span>
                    <DateTimeField source="created_at" showTime />
                  </Stack>
                </Typography>
              </RecordContextProvider>
            </Stack>
            <Stack direction="column" width="50%">
              <MarkdownField source="content" sx={baseAnswerSxProps} />
              <LawyerAnswerDateTimeBlock />
            </Stack>
          </Stack>
        </SimpleShowLayout>
      </ShowDialog>
    </>
  );
}
