import { required, useDataProvider, useNotify } from "react-admin";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import { DateTimeInput } from "@react-admin/ra-form-layout";
import { EkieDataProvider } from "../../../providers/dataProvider";
import ActionDialogButton from "../../../components/ActionDialogButton";
import { ScheduleOnboardingEmailsParams, ScheduleOnboardingEmailsResponse } from "../api";

export function ScheduleOnboardingEmailsButton() {
  const dataProvider = useDataProvider<EkieDataProvider>();
  const notify = useNotify();
  return (
    <ActionDialogButton<
      ScheduleOnboardingEmailsParams,
      ScheduleOnboardingEmailsParams,
      ScheduleOnboardingEmailsResponse
    >
      dialogTitle="customer.schedule_onboarding_emails.dialog.title"
      buttonProps={{
        label: "customer.schedule_onboarding_emails.button",
        color: "secondary",
        startIcon: <ForwardToInboxOutlinedIcon />,
        size: "small",
      }}
      dataProviderAction={dataProvider.scheduleOnboardingEmails}
      paramTransformer={(data: ScheduleOnboardingEmailsParams) => data}
      onSuccess={(response) => {
        notify("customer.schedule_onboarding_emails.success", {
          type: "success",
          messageArgs: { smart_count: response.scheduled_emails_count },
        });
      }}
    >
      <DateTimeInput
        source="at"
        label="customer.schedule_onboarding_emails.dialog.at"
        validate={required()}
        helperText="customer.schedule_onboarding_emails.dialog.at.helper"
      />
    </ActionDialogButton>
  );
}
