import { MarkdownField } from "@react-admin/ra-markdown";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useTranslate } from "react-admin";
import DateTimeField from "../../../../components/DateTimeField";
import { memberQuestionSxProps } from "../themes";

export default function MemberAdditionalRequest(): React.ReactNode {
  const translate = useTranslate();

  return (
    <>
      <MarkdownField source="request" sx={memberQuestionSxProps} />
      <Typography variant="body2" sx={{ color: grey[600] }} component="div">
        <Stack direction="row" spacing={0.5}>
          <span>{translate("question.edit.additionalRequestAt")}</span>
          <DateTimeField source="created_at" showTime />
        </Stack>
      </Typography>
    </>
  );
}
