import React from "react";
import { Stack } from "@mui/material";
import AssignationsCard from "./aside/AssignationsCard";
import AnswersCard from "./aside/AnswersCard";
import MemberCard from "../../../components/aside/MemberCard";

export default function QuestionAside(): React.ReactElement | null {
  return (
    <Stack display={{ xs: "none", lg: "block" }} sx={{ width: 400 }} ml={2} spacing={2}>
      <MemberCard />
      <AssignationsCard />
      <AnswersCard />
    </Stack>
  );
}
