import React from "react";
import { Card, CardContent, Stack } from "@mui/material";
import { Labeled, ReferenceField, TextField } from "react-admin";
import DateTimeField from "../../../components/DateTimeField";
import RatingNote from "../../../components/RatingNote";

export default function QuestionCard(): React.ReactElement {
  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Labeled>
            <ReferenceField
              reference="staff/questions"
              source="question_id"
              label="consultation.aside.question.id.label"
            >
              <TextField source="id" />
            </ReferenceField>
          </Labeled>
          <Labeled>
            <ReferenceField
              link={false}
              reference="staff/questions"
              source="question_id"
              label="consultation.aside.question.createdAt.label"
            >
              <DateTimeField source="created_at" />
            </ReferenceField>
          </Labeled>
          <Labeled>
            <ReferenceField
              link={false}
              reference="staff/questions"
              source="question_id"
              label="consultation.aside.question.memberRating.label"
            >
              <ReferenceField
                source="rating_id"
                reference="staff/answer-ratings"
                emptyText="common.field.missingValue"
              >
                <RatingNote<AnswerRating> />
              </ReferenceField>
            </ReferenceField>
          </Labeled>
        </Stack>
      </CardContent>
    </Card>
  );
}
