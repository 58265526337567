import { ChipProps } from "@mui/material/Chip/Chip";
import { ReactNode } from "react";
import { useTranslate } from "react-admin";
import { Chip } from "@mui/material";

export type ChipColors = "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";

export type ValueToColorMapping<T extends string | number> = Partial<{
  [key in T]: ChipColors;
}>;

export type ColoredChipProps<T extends string | number> = {
  value: T;
  colorMapping?: ValueToColorMapping<T>;
  defaultColor?: ChipColors;
  labelPrefix: string;
} & Omit<ChipProps, "color">;

export default function ColoredChip<T extends string | number>({
  value,
  colorMapping = {},
  labelPrefix,
  defaultColor = "default",
  ...rest
}: ColoredChipProps<T>): ReactNode {
  const translate = useTranslate();

  if (!value) return null;

  const color = colorMapping[value] || defaultColor;
  return (
    <Chip
      title={translate(`${labelPrefix}.${value}`)}
      color={color}
      label={translate(`${labelPrefix}.${value}`)}
      {...rest}
    />
  );
}
