import { ReactNode } from "react";

import { useTranslate } from "react-admin";
import { Typography } from "@mui/material";

import { formatDuration, intervalToDuration } from "date-fns";
import { fr } from "date-fns/locale";

interface RemainingDurationLabelProps {
  durationSeconds: number | null;
}

export default function RemainingDurationLabel({ durationSeconds }: RemainingDurationLabelProps): ReactNode {
  const translate = useTranslate();

  if (durationSeconds === null) {
    return "-";
  }

  const duration = intervalToDuration({ start: 0, end: Math.abs(durationSeconds * 1000) });
  const formattedDuration = formatDuration(duration, {
    format: ["years", "months", "weeks", "days", "hours", "minutes"],
    locale: fr,
  });

  if (durationSeconds < 0) {
    return (
      <Typography color="red" variant="body2">
        {translate("question.list.column.remainingTimeToAnswer.late", { duration: formattedDuration })}
      </Typography>
    );
  }
  return <Typography variant="body2">{formattedDuration}</Typography>;
}
