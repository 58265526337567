import { FileField, FileInput, useDataProvider, useNotify, useTranslate } from "react-admin";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { EkieDataProvider } from "../../../providers/dataProvider";
import ActionDialogButton from "../../../components/ActionDialogButton";
import { BulkUpdateMembersParams, BulkUpdateMembersResponse } from "../api";

export function BulkUpdateMembersButton() {
  const dataProvider = useDataProvider<EkieDataProvider>();
  const notify = useNotify();
  const translate = useTranslate();
  return (
    <ActionDialogButton<BulkUpdateMembersParams, BulkUpdateMembersParams, BulkUpdateMembersResponse>
      dialogTitle="customer.bulk_update_members.dialog.title"
      buttonProps={{
        label: "customer.bulk_update_members.button",
        color: "secondary",
        size: "small",
        startIcon: <FileUploadOutlinedIcon />,
      }}
      dataProviderAction={dataProvider.bulkUpdateMembers}
      paramTransformer={(data: BulkUpdateMembersParams) => data}
      onSuccess={(response) => {
        const createdMessage = translate("customer.bulk_update_members.created.success", {
          smart_count: response.created_count,
        });
        const updatedMessage = translate("customer.bulk_update_members.updated.success", {
          smart_count: response.updated_count,
        });
        const deactivatedMessage = translate("customer.bulk_update_members.deactivated.success", {
          smart_count: response.deactivated_count,
        });
        const deletedMessage = translate("customer.bulk_update_members.deleted.success", {
          smart_count: response.deleted_count,
        });
        notify([createdMessage, updatedMessage, deactivatedMessage, deletedMessage].join("\n"), {
          type: "success",
          multiLine: true,
          autoHideDuration: 5000,
        });
      }}
    >
      <FileInput source="customer_members_file" label=" ">
        <FileField source="src" title="title" />
      </FileInput>
    </ActionDialogButton>
  );
}
