import React, { ReactNode } from "react";
import { grey } from "@mui/material/colors";
import { Stack, Typography } from "@mui/material";
import { ReferenceField, TextField, useRecordContext, useTranslate } from "react-admin";
import DateTimeField from "../../../../components/DateTimeField";
import { Answer } from "../../api";

export default function LawyerAnswerDateTimeBlock(): ReactNode | null {
  const answer = useRecordContext<Answer>();
  const translate = useTranslate();
  if (!answer) return null;

  return (
    <Typography variant="body2" sx={{ color: grey[600] }} component="div">
      <Stack direction="row" spacing={0.5} justifyContent="flex-end">
        <span>
          {answer.status === "draft" ? translate("question.edit.draftedAt") : translate("question.edit.answeredAt")}
        </span>
        <DateTimeField source="created_at" showTime />
        <Typography variant="body2">{translate("question.edit.answeredBy")}</Typography>
        <ReferenceField
          source="lawyer_id"
          reference="staff/lawyers"
          label="question.edit.assignation.column.lawyer.title"
        >
          <TextField source="full_name" />
        </ReferenceField>
      </Stack>
    </Typography>
  );
}
