import { EventInput } from "@fullcalendar/core";
import { axiosInstance, fetchArrayResource } from "../../providers/api";
import {
  APPOINTMENT_CLOSED_COLOR,
  APPOINTMENT_PHONE_COLOR,
  APPOINTMENT_VIDEO_COLOR,
  AVAILABILITY_COLOR,
} from "./calendar/colors";

export type Lawyer = {
  id: number;
  last_name: string;
  first_name: string;
  status: "inactive" | "active" | "in_test";
  full_name: string;
  is_eligible_for_ai_suggestion_answer: boolean;
};

export type LawyerAvailability = {
  id: string;
  lawyer_id: number;
  start_at: string;
  end_at: string;
};

export type AppointmentCancelReason =
  | "lawyer_unavailable"
  | "lawyer_recused"
  | "lawyer_noshow"
  | "customer_unavailable"
  | "customer_dispute"
  | "customer_noshow"
  | "customer_order_cancellation"
  | "customer_move_cancellation"
  | "assignment_error"
  | "other";

export type LawyerAppointment = {
  id: number;
  consultation_id: number;
  lawyer_id: number;
  start_at: string;
  end_at: string;
  is_processed: boolean;
  can_be_rescheduled: boolean;
  appointment_type: number;
  incident_type: AppointmentCancelReason | null;
  member_id: number;
  title: string;
  video_room_id: string | null;
};

export type AppointmentExtendedProps = {
  consultation_id: number;
  appointment_id: number;
  event_type: "appointment";
};

export type AvailabilityExtendedProps = {
  availability_id: string;
  event_type: "availability";
};

export async function getListLawyerEvents(lawyerId: number, rangeFrom: string, rangeTo: string): Promise<EventInput[]> {
  const availabilities = await fetchArrayResource<LawyerAvailability>("staff/availabilities", {
    lawyer_id: lawyerId,
    range_from: rangeFrom,
    range_to: rangeTo,
  });

  const appointments = await fetchArrayResource<LawyerAppointment>("staff/appointments", {
    lawyer_id: lawyerId,
    range_from: rangeFrom,
    range_to: rangeTo,
    exclude_incidents: true,
  });

  const availabilitiesEvents: EventInput[] = availabilities.map((availability) => ({
    // Availability events are mixed with appointments one. We add a prefix to ensure id unicity
    id: `av-${availability.id}`,
    extendedProps: {
      availability_id: availability.id,
      event_type: "availability",
    } as AvailabilityExtendedProps,
    start: availability.start_at,
    end: availability.end_at,
    title: "",
    display: "background",
    groupId: "availability",
    color: AVAILABILITY_COLOR,
  }));

  const appointmentsEvents: EventInput[] = appointments.map((appointment) => ({
    // Appointment events are mixed with availability ones. We add a prefix to ensure id unicity
    id: `ap-${appointment.id}`,
    extendedProps: {
      appointment_id: appointment.id,
      consultation_id: appointment.consultation_id,
      event_type: "appointment",
    } as AppointmentExtendedProps,
    start: appointment.start_at,
    end: appointment.end_at,
    title: appointment.title,
    editable: appointment.can_be_rescheduled,
    durationEditable: false,
    // eslint-disable-next-line no-nested-ternary
    color: !appointment.is_processed
      ? appointment.video_room_id
        ? APPOINTMENT_VIDEO_COLOR
        : APPOINTMENT_PHONE_COLOR
      : APPOINTMENT_CLOSED_COLOR,
  }));

  return [...availabilitiesEvents, ...appointmentsEvents];
}

export const lawyerProviderMethods = {
  async rescheduleAppointment(appointmentId: number, startAt: string): Promise<LawyerAppointment> {
    return axiosInstance.post(`/staff/appointments/${appointmentId}/reschedule`, { start_at: startAt });
  },
};
