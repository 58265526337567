import React from "react";
import { Stack } from "@mui/material";
import MemberCard from "../../components/aside/MemberCard";
import QuestionCard from "./aside/QuestionCard";

export default function ConsultationAside(): React.ReactElement {
  return (
    <Stack display={{ xs: "none", lg: "block" }} sx={{ width: 400 }} ml={2} spacing={2}>
      <MemberCard />
      <QuestionCard />
    </Stack>
  );
}
