import React, { ReactNode } from "react";
import { BooleanInput, email, required, SimpleForm, TextInput, useRecordContext } from "react-admin";
import { Grid, Stack } from "@mui/material";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { Customer } from "../customer/api";

export default function CreateMemberDialogButton(): ReactNode | null {
  const customer = useRecordContext<Customer>();
  if (!customer) return null;
  return (
    <CreateInDialogButton
      record={{ customer_id: customer.id }}
      resource="staff/members"
      ButtonProps={{ variant: "outlined", color: "secondary" }}
    >
      <SimpleForm>
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <TextInput fullWidth source="last_name" label="member.lastName" />
          </Grid>
          <Grid item xs={6}>
            <TextInput fullWidth source="first_name" label="member.firstName" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="email" label="member.email" validate={[required(), email()]} />
          </Grid>
          <Grid item xs={0} />
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <BooleanInput source="is_cse_member" label="member.isCseMember" />
              <BooleanInput source="is_admin" label="member.isAdmin" />
            </Stack>
          </Grid>
        </Grid>
      </SimpleForm>
    </CreateInDialogButton>
  );
}
