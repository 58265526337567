import {
  ArrayInput,
  NumberInput,
  SimpleFormIterator,
  BooleanInput,
  useEditContext,
  useTranslate,
  TextInput,
  SimpleForm,
} from "react-admin";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, InputAdornment } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { DispatchConfigResponse } from "../../../providers/api";
import LawyerInput from "./LawyerInput";

export type ConfigByDomain = {
  [key: string]: {
    domainName: string;
    configs: DispatchConfigResponse["configs"];
  };
};

function organizeConfigsByDomain(response: DispatchConfigResponse) {
  const { configs, domains } = response;
  const configsByDomain: ConfigByDomain = {};

  domains.forEach((domain) => {
    configsByDomain[domain.id] = {
      domainName: domain.name,
      configs: [],
    };
  });

  configs.forEach((config) => {
    if (configsByDomain[config.law_domain_id]) {
      configsByDomain[config.law_domain_id].configs.push(config);
    }
  });

  return configsByDomain;
}

function DispatchConfigForm() {
  const { record } = useEditContext<DispatchConfigResponse>();
  const translate = useTranslate();
  if (!record) return <> </>;
  const configs = organizeConfigsByDomain(record);

  return (
    <SimpleForm record={configs}>
      <Box sx={{ width: "60%" }}>
        {record.domains.map((domain) => (
          <Accordion key={domain.id} slotProps={{ transition: { unmountOnExit: true } }}>
            <AccordionSummary
              sx={{ width: "100" }}
              expandIcon={<ExpandMore />}
              aria-controls="domain-content"
              id="domain-header"
            >
              {translate("dispatch.edit.form.accordion.label", {
                domainName: domain.name,
                total: configs[domain.id].configs.length,
              })}
            </AccordionSummary>
            <AccordionDetails>
              <ArrayInput hiddenLabel label="" source={`${domain.id}.configs`}>
                <SimpleFormIterator sx={{ marginLeft: 0 }} disableClear disableReordering>
                  <Box display="flex" marginY={1} alignItems="center">
                    <Grid container display="flex" alignItems="center" spacing={5}>
                      <Grid display="flex" alignItems="center" item xs={5}>
                        <LawyerInput />
                      </Grid>
                      <TextInput sx={{ display: "none" }} hidden source="law_domain_id" defaultValue={domain.id} />
                      <Grid item xs={2.5}>
                        <NumberInput
                          size="small"
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          }}
                          sx={{ marginTop: 0 }}
                          min={0}
                          max={100}
                          label=""
                          hiddenLabel
                          source="percent"
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <BooleanInput defaultValue label="" helperText={false} source="enabled" />
                      </Grid>
                    </Grid>
                  </Box>
                </SimpleFormIterator>
              </ArrayInput>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </SimpleForm>
  );
}

export default DispatchConfigForm;
