import { FormDataConsumer, ReferenceInput, SelectInput } from "react-admin";

function LawyerInput() {
  return (
    <FormDataConsumer>
      {({ scopedFormData: currentItem }) => {
        return (
          <ReferenceInput
            source="lawyer_id"
            reference="staff/lawyers"
            alwaysOn
            filter={{ status: ["active", "in_test"] }}
          >
            <SelectInput
              sx={currentItem?.id && { backgroundColor: "#e0e0e0", opacity: 0.6 }}
              InputProps={{ readOnly: currentItem?.id }}
              helperText={false}
              margin="none"
              label="lawyer"
            />
          </ReferenceInput>
        );
      }}
    </FormDataConsumer>
  );
}

export default LawyerInput;
