import { ReactNode } from "react";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { ResourceContextProvider, useRecordContext } from "react-admin";
import ProductForm from "./ProductForm";
import { Customer } from "../api";

export default function CreateProductDialogButton(): ReactNode | null {
  const record = useRecordContext<Customer>();
  if (!record) return null;

  return (
    <CreateInDialogButton
      resource="staff/products"
      record={{ customer_id: record.id }}
      maxWidth="md"
      fullWidth
      ButtonProps={{ variant: "outlined", color: "secondary" }}
    >
      <ResourceContextProvider value="staff/products">
        <ProductForm />
      </ResourceContextProvider>
    </CreateInDialogButton>
  );
}
