import { ReactNode } from "react";
import { Button, useRecordContext } from "react-admin";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Customer } from "../api";
import { getAuthenticationData } from "../../../providers/authProvider";

export function BulkDownloadMembersButton(): ReactNode | null {
  const customer = useRecordContext<Customer>();
  if (!customer) return null;

  const extractUrl = `${import.meta.env.VITE_API_BASE_URL}/staff/customers/${customer.id}/members-bulk-export`;
  return (
    <Button
      label="customer.bulk_export_members.button"
      color="secondary"
      size="small"
      startIcon={<FileDownloadOutlinedIcon />}
      onClick={() => {
        // Since we are using jwt based auth, if we open the link that will trigger the download
        // in a new tab, the token will not be set in request header.
        // The workaround to solve this is to create a form with the token in it and submit it.
        // The backend allows token to be passed in a form so it will accept the query.

        // Another alternative would be to download the file with axios and put in, in a file.
        // That would work but downloading using the browser is better for UX.
        const form = document.createElement("form");
        form.method = "post";
        form.target = "_blank";
        form.action = extractUrl;
        const token = getAuthenticationData().accessToken;
        form.innerHTML = `<input type="hidden" name="access_token" value="${token}">`;

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
      }}
    />
  );
}
