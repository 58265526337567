import {
  List,
  Datagrid,
  NumberField,
  ReferenceField,
  FunctionField,
  TextField,
  ChipField,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  SelectInput,
} from "react-admin";

import RemainingDurationLabel from "../RemainingDurationLabel";
import DateTimeField from "../../../components/DateTimeField";
import { Question, UnifiedStatus } from "../api";
import ColoredChip, { ValueToColorMapping } from "../../../components/ColoredChip";

const unansweredColorMapping: ValueToColorMapping<UnifiedStatus> = {
  assigned: "warning",
  assignment_accepted: "success",
};

export default function UnansweredQuestionsList() {
  return (
    <List
      resource="staff/questions"
      title="question.unansweredList.title"
      perPage={25}
      exporter={false}
      storeKey={false}
      filterDefaultValues={{ is_assigned_or_assignment_accepted: true }}
      filters={[
        <SelectInput
          alwaysOn
          source="unified_status"
          label="question.list.filter.status.label"
          sx={{ minWidth: 270 }}
          choices={[
            { id: "assignment_accepted", name: "question.edit.status.assignment_accepted" },
            { id: "assigned", name: "question.edit.status.assigned" },
          ]}
        />,
        <ReferenceInput
          source="assigned_lawyer_id"
          reference="staff/lawyers"
          alwaysOn
          filter={{ status: ["active", "in_test"] }}
          perPage={200} // Today (2024) we have 25 lawyers, it's not necessary to use an autocomplete
        >
          <SelectInput label="lawyer" sx={{ minWidth: 250 }} />
        </ReferenceInput>,
        <ReferenceInput reference="staff/law-domains" source="law_domain_id" alwaysOn>
          <AutocompleteInput label="question.list.filter.law_domain.label" sx={{ minWidth: 280 }} />
        </ReferenceInput>,
        <DateTimeInput source="created_at_from" label="question.list.filter.createdAtFrom.label" alwaysOn />,
        <DateTimeInput source="created_at_to" label="question.list.filter.createdAtTo.label" alwaysOn />,
        <SelectInput
          alwaysOn
          source="answer_deadline"
          label="question.list.filter.timeToAnswer.label"
          sx={{ minWidth: 250 }}
          choices={[
            { id: "gte_3h", name: "question.list.filter.timeToAnswer.value.moreThan3h" },
            { id: "lte_3h", name: "question.list.filter.timeToAnswer.value.lessThan3h" },
            { id: "lte_1h", name: "question.list.filter.timeToAnswer.value.lessThan1h" },
          ]}
        />,
      ]}
    >
      <Datagrid size="small" bulkActionButtons={false} rowClick="edit">
        <NumberField source="id" sortable={false} />
        <DateTimeField label="question.list.column.askedDate.title" source="created_at" sortable={false} showTime />

        <ReferenceField
          label="question.list.column.customer.title"
          source="customer_id"
          reference="staff/customers"
          emptyText="question.list.column.customer.b2c"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          label="question.list.column.source.title"
          source="customer_id"
          reference="staff/customers"
          emptyText="question.list.column.customer.b2c"
          sortable={false}
        >
          <TextField source="source_label" />
        </ReferenceField>

        <ReferenceField
          label="question.list.column.member.title"
          source="member_id"
          reference="staff/members"
          sortable={false}
        />

        <ReferenceField
          label="question.list.column.domain.title"
          source="law_domain_id"
          reference="staff/law-domains"
          sortable={false}
        >
          <ChipField source="name" size="small" />
        </ReferenceField>

        <ReferenceField
          label="question.list.column.acceptedAssignedLawyers.title"
          reference="staff/lawyers"
          source="assigned_lawyer_id"
          sortable={false}
        />

        <FunctionField<Question>
          label="question.list.column.assignation.status.title"
          render={(question) => (
            <ColoredChip
              value={question.unified_status}
              colorMapping={unansweredColorMapping}
              labelPrefix="question.edit.assignation.status"
              size="small"
            />
          )}
        />

        <DateTimeField
          label="question.list.column.deadline.title"
          source="answer_deadline_at"
          sortable={false}
          showTime
        />

        <FunctionField<Question>
          label="question.list.column.remainingTimeToAnswer.title"
          source="remaining_time_to_answer_seconds"
          sortable={false}
          render={(record) => <RemainingDurationLabel durationSeconds={record.remaining_time_to_answer_seconds} />}
        />
      </Datagrid>
    </List>
  );
}
