import {
  AutocompleteArrayInput,
  BooleanInput,
  DateInput,
  Edit,
  email,
  ReferenceArrayInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { Grid, Stack, Typography } from "@mui/material";
import { Lawyer } from "./api";
import CityInput from "../city/CityInput";
import PhoneInput from "../../components/PhoneInput";
import LawyerEditToolbar from "./toolbar/LawyerEditToolbar";
import LawyerActionsToolbar from "./toolbar/LawyerActionsToolbar";

function LawyerEditTitle() {
  const translate = useTranslate();
  const record = useRecordContext<Lawyer>();
  if (!record) return null;

  return <span>{translate("lawyer.edit.title", { firstName: record.first_name, lastName: record.last_name })}</span>;
}

export default function LawyerEdit() {
  const translate = useTranslate();

  return (
    <Edit mutationMode="pessimistic" title={<LawyerEditTitle />} actions={<LawyerActionsToolbar />}>
      <SimpleForm toolbar={<LawyerEditToolbar />}>
        <Grid container maxWidth={{ xs: "100%", lg: 1200 }} columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} lg={6}>
            <Stack spacing={{ xs: 2, lg: 2 }}>
              <Typography component="h2" variant="h5">
                {translate("lawyer.edit.section.contact.title")}
              </Typography>
              <Stack direction={{ xs: "column", lg: "row" }} mt={1} spacing={{ xs: 0, lg: 2 }}>
                <TextInput validate={required()} fullWidth source="last_name" label="lawyer.edit.form.lastName.label" />
                <TextInput
                  fullWidth
                  validate={required()}
                  source="first_name"
                  label="lawyer.edit.form.firstName.label"
                />
              </Stack>
              <Stack direction={{ xs: "column" }} spacing={{ xs: 0, lg: 2 }}>
                <TextInput source="email" label="lawyer.edit.form.email.label" validate={email()} />
                <PhoneInput name="phone_cellphone" label="lawyer.edit.form.phoneCellPhone.label" />
                <PhoneInput name="phone_landline" label="lawyer.edit.form.phoneLandline.label" />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={{ xs: 1, lg: 2 }}>
              <Typography component="h2" variant="h5">
                {translate("lawyer.edit.section.skills.title")}
              </Typography>
              <ReferenceArrayInput source="law_domain_ids" reference="staff/law-domains" perPage={100}>
                <AutocompleteArrayInput margin="none" label="lawyer.edit.form.law.label">
                  <TextField source="name" />
                </AutocompleteArrayInput>
              </ReferenceArrayInput>
              <ReferenceArrayInput margin="none" source="language_ids" reference="staff/languages">
                <AutocompleteArrayInput margin="none" label="lawyer.edit.form.languages" />
              </ReferenceArrayInput>
              <DateInput source="oath_date" label="lawyer.edit.form.oathDate" />
              <SelectInput
                label="lawyer.edit.section.state.title"
                source="status"
                fullWidth
                margin="dense"
                validate={required()}
                choices={[
                  { id: "active", name: "lawyer.list.column.status.active" },
                  { id: "in_test", name: "lawyer.list.column.status.in_test" },
                  { id: "inactive", name: "lawyer.list.column.status.inactive" },
                ]}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={{ xs: 1, lg: 2 }}>
              <Typography component="h2" variant="h5">
                {translate("lawyer.edit.section.lawfirm.title")}
              </Typography>
              <TextInput source="lawfirm" label="lawyer.edit.form.lawFirm.label" />
              <TextInput validate={required()} label="lawyer.edit.form.streetLine1.label" source="street_line1" />
              <TextInput rows={4} label="lawyer.edit.form.streetLine2.label" source="street_line2" />
              <CityInput />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={{ xs: 1, lg: 2 }}>
              <Typography component="h2" variant="h5">
                {translate("lawyer.edit.form.misc")}
              </Typography>
              <TextInput multiline source="private_notes" label="lawyer.edit.form.privateNotes.label" rows={5} />
              <Stack direction="row">
                <BooleanInput
                  label="lawyer.edit.form.isEligibleForAiSuggestionAnswer"
                  source="is_eligible_for_ai_suggestion_answer"
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
}
