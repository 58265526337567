import {
  List,
  Datagrid,
  NumberField,
  ReferenceField,
  FunctionField,
  TextField,
  ChipField,
  SingleFieldList,
  useTranslate,
  ReferenceArrayField,
} from "react-admin";

import RemainingDurationLabel from "../RemainingDurationLabel";
import DateTimeField from "../../../components/DateTimeField";
import { Question } from "../api";
import LawyerAssignationStatusChip from "../AssignationStatusChip";

export default function AssignableQuestionsList() {
  const translate = useTranslate();
  return (
    <List
      resource="staff/questions"
      title="question.assignableList.title"
      perPage={25}
      exporter={false}
      storeKey={false}
      filter={{ unified_status: ["pending_qualification", "assignment_rejected", "awaiting_manual_check"] }}
    >
      <Datagrid size="small" bulkActionButtons={false} rowClick="edit">
        <NumberField source="id" sortable={false} />
        <DateTimeField label="question.list.column.askedDate.title" source="created_at" sortable={false} showTime />

        <ReferenceField
          label="question.list.column.customer.title"
          source="customer_id"
          reference="staff/customers"
          emptyText="question.list.column.customer.b2c"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          label="question.list.column.source.title"
          source="customer_id"
          reference="staff/customers"
          emptyText="question.list.column.customer.b2c"
          sortable={false}
        >
          <TextField source="source_label" />
        </ReferenceField>

        <ReferenceField
          label="question.list.column.member.title"
          source="member_id"
          reference="staff/members"
          sortable={false}
        />

        <ReferenceField
          label="question.list.column.domain.title"
          source="law_domain_id"
          reference="staff/law-domains"
          sortable={false}
        >
          <ChipField source="name" size="small" />
        </ReferenceField>

        <ReferenceArrayField
          label="question.list.column.assignedLawyers.title"
          reference="staff/question-assignations"
          source="assignation_ids"
          sortable={false}
        >
          <SingleFieldList empty={<span>{translate("common.field.missingValue")}</span>}>
            <LawyerAssignationStatusChip />
          </SingleFieldList>
        </ReferenceArrayField>

        <DateTimeField
          label="question.list.column.deadline.title"
          source="answer_deadline_at"
          sortable={false}
          showTime
        />

        <FunctionField<Question>
          label="question.list.column.remainingTimeToAnswer.title"
          source="remaining_time_to_answer_seconds"
          sortable={false}
          render={(record) => <RemainingDurationLabel durationSeconds={record.remaining_time_to_answer_seconds} />}
        />
      </Datagrid>
    </List>
  );
}
