import { usePermissions } from "react-admin";
import { MultiLevelMenu } from "@react-admin/ra-navigation";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import { ROLE_OPERATIONS } from "./providers/authProvider";

export default function Menu() {
  const { permissions } = usePermissions<string[]>();

  return (
    <MultiLevelMenu initialOpen>
      <MultiLevelMenu.Item name="members" to="/staff/members" label="nav.members.label" icon={<PersonOutlinedIcon />} />
      <MultiLevelMenu.Item
        name="customers"
        to="/staff/customers"
        label="nav.customers.label"
        icon={<BusinessOutlinedIcon />}
      />
      {permissions?.includes(ROLE_OPERATIONS) && (
        <>
          <MultiLevelMenu.Item name="lawyers" label="nav.lawyer.label" icon={<GavelOutlinedIcon />}>
            <MultiLevelMenu.Item
              name="lawyers.list"
              label="lawyers"
              to="/staff/lawyers"
              icon={<PersonPinOutlinedIcon />}
            />
            <MultiLevelMenu.Item
              name="lawyers.calendar"
              label="calendar"
              to="/lawyer-events"
              icon={<CalendarMonthOutlinedIcon />}
            />
            <MultiLevelMenu.Item
              name="lawyers.dispatch"
              label="dispatch"
              to="/staff/dispatch-config/edit"
              icon={<TuneOutlinedIcon />}
            />
          </MultiLevelMenu.Item>
          <MultiLevelMenu.Item name="questions" label="question.menu.label" icon={<QuestionAnswerOutlinedIcon />}>
            <MultiLevelMenu.Item
              name="staff/questions/assignable"
              to="/staff/questions/assignable"
              label="question.menu.assignable"
              icon={<AssignmentLateOutlinedIcon />}
            />
            <MultiLevelMenu.Item
              name="staff/questions/unanswered"
              to="/staff/questions/unanswered"
              label="question.menu.unanswered"
              icon={<PendingOutlinedIcon />}
            />
            <MultiLevelMenu.Item
              name="staff/questions/answer-to-validate"
              to="/staff/questions/answer-to-validate"
              label="question.menu.answerToValidate"
              icon={<HelpOutlineOutlinedIcon />}
            />
            <MultiLevelMenu.Item
              name="staff/questions/answered"
              to="/staff/questions/answered"
              label="question.menu.answered"
              icon={<CheckCircleOutlineIcon />}
            />
          </MultiLevelMenu.Item>
          <MultiLevelMenu.Item
            name="consultations"
            to="/staff/consultations"
            label="nav.consultations.label"
            icon={<PhoneOutlinedIcon />}
          />
        </>
      )}
    </MultiLevelMenu>
  );
}
