import { EventChangeArg } from "@fullcalendar/core";
import { Confirm, useDataProvider, useNotify, useRefresh } from "react-admin";
import { Stack, Typography } from "@mui/material";
import { KeyboardDoubleArrowDownOutlined } from "@mui/icons-material";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { LONG_MONTH_DATETIME_WITHOUT_SECONDS } from "../../../constants/date_formats";

type MoveConsultationDialogPros = {
  eventChange: EventChangeArg | null;
  onClose: () => void;
};

export default function MoveConsultationDialog({ eventChange, onClose }: MoveConsultationDialogPros) {
  const notify = useNotify();
  const refresh = useRefresh();

  const dataProvider = useDataProvider();

  const cancelEventChange = () => {
    eventChange?.revert();
    onClose();
  };

  const { mutate: reschedule, isPending } = useMutation({
    mutationFn: () => {
      const id = eventChange!.event.extendedProps.appointment_id;
      return dataProvider.rescheduleAppointment(id, eventChange!.event.startStr);
    },
    onSuccess: () => {
      refresh();
      onClose();
    },
    onError: (error: Error) => {
      notify(error.message, { type: "error" });
      cancelEventChange();
    },
  });

  const confirmEventChange = async () => {
    return reschedule();
  };

  return (
    <Confirm
      isOpen={eventChange !== null}
      loading={isPending}
      title="lawyer.appointment.move.confirmTitle"
      translateOptions={{ title: eventChange?.event.title }}
      content={
        <Stack direction="column" spacing={1} alignItems="center" mt={1}>
          <Typography variant="body1" sx={{ textDecoration: "line-through" }}>
            {eventChange?.oldEvent.start!.toLocaleString(undefined, LONG_MONTH_DATETIME_WITHOUT_SECONDS) ?? ""}
          </Typography>
          <KeyboardDoubleArrowDownOutlined fontSize="medium" />
          <Typography variant="body1">
            {eventChange?.event.start!.toLocaleString(undefined, LONG_MONTH_DATETIME_WITHOUT_SECONDS) ?? ""}
          </Typography>
        </Stack>
      }
      confirmColor="warning"
      onConfirm={confirmEventChange}
      onClose={cancelEventChange}
    />
  );
}
