import React from "react";
import { MarkdownField } from "@react-admin/ra-markdown";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FunctionField, useTranslate } from "react-admin";
import DateTimeField from "../../../../components/DateTimeField";
import { Question } from "../../api";
import RemainingDurationLabel from "../../RemainingDurationLabel";
import { memberQuestionSxProps } from "../themes";

export default function MemberQuestion(): React.ReactNode {
  const translate = useTranslate();
  return (
    <>
      <span>
        <MarkdownField source="content" sx={memberQuestionSxProps} />
      </span>
      <Typography variant="body2" sx={{ color: grey[600] }} component="div">
        <Stack direction="row" spacing={0.5}>
          <span>{translate("question.edit.askedAt")}</span>
          <DateTimeField source="created_at" showTime />
        </Stack>

        <FunctionField<Question>
          sortable={false}
          render={(record) =>
            record.remaining_time_to_answer_seconds && (
              <Stack direction="row" spacing={0.5}>
                <span>{translate("question.edit.remainingTimeToAnswerPrefix")}</span>
                <RemainingDurationLabel durationSeconds={record.remaining_time_to_answer_seconds} />
              </Stack>
            )
          }
        />
      </Typography>
    </>
  );
}
