import { ReactNode } from "react";
import { FunctionField, ReferenceField, useRecordContext, useTranslate } from "react-admin";
import { Chip } from "@mui/material";
import { Assignation } from "./api";
import { Lawyer } from "../lawyer/api";

export default function LawyerAssignationStatusChip(): ReactNode {
  const translate = useTranslate();
  const assignation = useRecordContext<Assignation>();

  if (!assignation) return null;

  let color: "error" | "warning" | "success";
  switch (assignation.status) {
    case "rejected":
      color = "error";
      break;
    case "pending":
      color = "warning";
      break;
    default:
      color = "success";
      break;
  }
  return (
    <ReferenceField source="lawyer_id" reference="staff/lawyers">
      <FunctionField<Lawyer>
        render={(record) => (
          <Chip
            color={color}
            size="small"
            label={record.full_name}
            title={translate(`question.assignation.status.${assignation.status}`)}
          />
        )}
      />
    </ReferenceField>
  );
}
