import {
  List,
  NumberField,
  ReferenceField,
  TextField,
  Datagrid,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  DateTimeInput,
  ChipField,
} from "react-admin";
import React from "react";
import DateTimeField from "../../../components/DateTimeField";
import RatingNote from "../../../components/RatingNote";
import { AnswerRating, Question } from "../api";
import ColoredChip from "../../../components/ColoredChip";
import { detailColorMapping } from "../edit/themes";

export default function MemberQuestionsList() {
  return (
    <List
      resource="staff/questions"
      title="question.memberList.title"
      perPage={25}
      exporter={false}
      storeKey={false}
      filterDefaultValues={{
        order_by: ["-created_at"],
      }}
      filters={[
        <ReferenceInput
          source="assigned_lawyer_id"
          reference="staff/lawyers"
          alwaysOn
          filter={{ status: ["active", "in_test"] }}
          perPage={200} // Today (2024) we have 25 lawyers, it's not necessary to use an autocomplete
        >
          <SelectInput label="lawyer" sx={{ minWidth: 250 }} />
        </ReferenceInput>,
        <ReferenceInput reference="staff/law-domains" source="law_domain_id" alwaysOn>
          <AutocompleteInput label="question.list.filter.law_domain.label" sx={{ minWidth: 280 }} />
        </ReferenceInput>,
        <DateTimeInput source="created_at_from" label="question.list.filter.createdAtFrom.label" alwaysOn />,
        <DateTimeInput source="created_at_to" label="question.list.filter.createdAtTo.label" alwaysOn />,
        <SelectInput
          label="question.list.filter.memberRating.label"
          source="member_answer_rating_note"
          alwaysOn
          choices={[
            { id: "1", name: "★☆☆☆☆" },
            { id: "2", name: "★★☆☆☆" },
            { id: "3", name: "★★★☆☆" },
            { id: "4", name: "★★★★☆" },
            { id: "5", name: "★★★★★" },
          ]}
        />,
      ]}
    >
      <Datagrid size="small" bulkActionButtons={false} rowClick="edit">
        <NumberField source="id" sortable={false} />
        <DateTimeField label="question.list.column.askedDate.title" source="created_at" sortable={false} showTime />

        <ReferenceField
          label="question.list.column.customer.title"
          source="customer_id"
          reference="staff/customers"
          emptyText="question.list.column.customer.b2c"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          label="question.list.column.source.title"
          source="customer_id"
          reference="staff/customers"
          emptyText="question.list.column.customer.b2c"
          sortable={false}
        >
          <TextField source="source_label" />
        </ReferenceField>

        <ReferenceField
          label="question.list.column.domain.title"
          source="law_domain_id"
          reference="staff/law-domains"
          sortable={false}
        >
          <ChipField source="name" size="small" />
        </ReferenceField>

        <ReferenceField
          label="question.list.column.acceptedAssignedLawyers.title"
          reference="staff/lawyers"
          source="assigned_lawyer_id"
          sortable={false}
        >
          <TextField source="full_name" />
        </ReferenceField>

        <DateTimeField
          label="question.list.column.deadline.title"
          source="answer_deadline_at"
          sortable={false}
          showTime
        />
        <FunctionField<Question>
          label="question.list.column.status.title"
          render={(question) => (
            <ColoredChip
              value={question.unified_status}
              colorMapping={detailColorMapping}
              labelPrefix="question.edit.status"
              size="small"
            />
          )}
        />
        <ReferenceField
          label="question.list.column.memberRating.title"
          source="rating_id"
          reference="staff/answer-ratings"
          sortable={false}
        >
          <RatingNote<AnswerRating> />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
