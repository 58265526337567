import { addRefreshAuthToAuthProvider, AuthProvider } from "react-admin";

import { jwtDecode } from "jwt-decode";

import { InternalAxiosRequestConfig } from "axios";
import {
  axiosInstance,
  getTokensFromAuthorizationCode,
  getTokensFromRefreshToken,
  getUserInfo,
  TokenPair,
} from "./api";

export const ROLE_CUSTOMER_SERVICE = "Customer Service";
export const ROLE_OPERATIONS = "Operations";

const ACCESS_TOKEN_STORAGE_KEY = "fusionToken";
const REFRESH_TOKEN_STORAGE_KEY = "fusionRefreshToken";
const ROLES_STORAGE_KEY = "fusionRoles";

function saveAuthenticationData(accessToken: string, refreshToken: string) {
  const { roles } = jwtDecode<{ roles: string[] }>(accessToken);
  localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
  localStorage.setItem(ROLES_STORAGE_KEY, JSON.stringify(roles));
}

export function getAuthenticationData(): {
  accessToken: string | null;
  refreshToken: string | null;
  roles: string[] | null;
} {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  const roles = localStorage.getItem(ROLES_STORAGE_KEY);
  if (!accessToken || !refreshToken || !roles) {
    return { accessToken: null, refreshToken: null, roles: null };
  }
  return {
    accessToken,
    refreshToken,
    roles: JSON.parse(roles),
  };
}

function clearAuthenticationData() {
  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  localStorage.removeItem(ROLES_STORAGE_KEY);
}

function getTokenRemainingDurationSeconds(accessToken: string): number {
  const { exp } = jwtDecode<{ exp: number }>(accessToken);
  return exp - Date.now() / 1000;
}

let refreshPromise: Promise<TokenPair> | null = null;

export const refreshAuthToken = async () => {
  const { refreshToken, accessToken } = getAuthenticationData();
  if (!accessToken || !refreshToken) return;
  if (refreshPromise) {
    // Some concurrent call is already fetching a new refresh token
    await refreshPromise;
    return;
  }
  if (getTokenRemainingDurationSeconds(accessToken) < 30) {
    try {
      refreshPromise = getTokensFromRefreshToken(refreshToken);
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = await refreshPromise;
      saveAuthenticationData(newAccessToken, newRefreshToken);
    } finally {
      refreshPromise = null;
    }
  }
};

let handleCallbackPromise: Promise<TokenPair> | null = null;

const baseAuthProvider: AuthProvider = {
  login: async () => {},
  logout: async () => {
    clearAuthenticationData();
  },
  checkError: (error) => {
    if (error.status === 401) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const { accessToken } = getAuthenticationData();
    if (accessToken == null) {
      throw new Error("Not authenticated");
    }
  },
  getPermissions: async () => {
    const { roles } = getAuthenticationData();
    return roles || [];
  },
  async getIdentity() {
    const userInfo = await getUserInfo();
    return {
      id: userInfo.email,
      fullName: userInfo.full_name,
      avatar: userInfo.picture,
    };
  },
  handleCallback: async () => {
    /* That's a workaround due to a bug in react-admin caused by React StrictMode:
       This method is called twice when StrictMode is enabled. */
    if (handleCallbackPromise !== null) {
      await handleCallbackPromise;
      return;
    }
    try {
      const authorizationCode = new URLSearchParams(window.location.search).get("code");
      if (!authorizationCode) {
        throw new Error("Missing 'code' parameter in the URL");
      }
      handleCallbackPromise = getTokensFromAuthorizationCode(authorizationCode);
      const { accessToken, refreshToken } = await handleCallbackPromise;
      saveAuthenticationData(accessToken, refreshToken);
    } finally {
      handleCallbackPromise = null;
    }
  },
};

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const { accessToken } = getAuthenticationData();
    if (accessToken !== null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

export default addRefreshAuthToAuthProvider(baseAuthProvider, refreshAuthToken);
