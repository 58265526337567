import React, { ReactNode } from "react";
import { LawyerAppointment } from "../../lawyer/api";
import ColoredChip from "../../../components/ColoredChip";

export default function AppointmentStatus({ appointment }: { appointment: LawyerAppointment }): ReactNode {
  let status: string;
  if (appointment.is_processed && appointment.incident_type !== null) {
    status = appointment.incident_type;
  } else if (appointment.is_processed) {
    status = "finalized";
  } else {
    status = "pending";
  }
  return (
    <ColoredChip
      value={status}
      labelPrefix="consultation.edit.appointment.status"
      defaultColor="error"
      size="small"
      colorMapping={{ finalized: "success", pending: "warning" }}
    />
  );
}
