import React, { ReactNode } from "react";
import {
  ChipField,
  Datagrid,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import DateTimeField from "../../components/DateTimeField";
import ColoredChip from "../../components/ColoredChip";
import { AppointmentRating, Consultation, consultationTypes } from "./api";
import { statusColorMapping } from "./themes";
import RatingNote from "../../components/RatingNote";

export default function ConsultationList(): ReactNode {
  return (
    <List
      perPage={25}
      exporter={false}
      storeKey={false}
      title="consultation.list.title"
      filters={[
        <TextInput label="member.lastName" source="member_last_name" resettable alwaysOn />,
        <TextInput label="member.firstName" source="member_first_name" resettable alwaysOn />,
        <TextInput label="member.email" source="member_email" resettable alwaysOn />,
        <ReferenceInput
          source="lawyer_id"
          reference="staff/lawyers"
          alwaysOn
          filter={{ status: ["active", "in_test"] }}
          perPage={200} // Today (2024) we have 25 lawyers, it's not necessary to use an autocomplete
        >
          <SelectInput label="lawyer" sx={{ minWidth: 250 }} />
        </ReferenceInput>,
        <SelectInput
          sx={{ minWidth: 210 }}
          alwaysOn
          source="status"
          choices={consultationTypes.map((status) => ({
            id: status,
            name: `consultation.list.column.status.${status}`,
          }))}
        />,
        <SelectInput
          label="consultation.list.filter.memberRating.label"
          source="member_appointment_rating_note"
          alwaysOn
          choices={[
            { id: "1", name: "★☆☆☆☆" },
            { id: "2", name: "★★☆☆☆" },
            { id: "3", name: "★★★☆☆" },
            { id: "4", name: "★★★★☆" },
            { id: "5", name: "★★★★★" },
          ]}
        />,
      ]}
    >
      <Datagrid size="small" bulkActionButtons={false} rowClick="edit">
        <NumberField source="id" label="consultation.list.column.id.title" sortable={false} />
        <DateTimeField source="created_at" label="consultation.list.column.createdAt.title" sortable={false} showTime />
        <ReferenceField
          source="member_id"
          label="consultation.list.column.member.title"
          reference="staff/members"
          sortable={false}
        />
        <ReferenceField
          label="consultation.list.column.domain.title"
          source="law_domain_id"
          reference="staff/law-domains"
          sortable={false}
        >
          <ChipField source="name" size="small" />
        </ReferenceField>
        <ReferenceField
          label="consultation.list.column.lawyer.title"
          reference="staff/lawyers"
          source="lawyer_id"
          sortable={false}
        />

        <DateTimeField
          label="consultation.list.column.appointmentScheduledStartAt.title"
          source="appointment_start_at"
          sortable={false}
          showTime
        />
        <FunctionField<Consultation>
          sortable={false}
          label="consultation.list.column.status.title"
          render={(consultation) => (
            <ColoredChip
              value={consultation.status}
              size="small"
              labelPrefix="consultation.list.column.status"
              colorMapping={statusColorMapping}
            />
          )}
        />
        <ReferenceField
          label="consultation.list.column.memberRating.title"
          source="rating_id"
          reference="staff/appointment-ratings"
          sortable={false}
        >
          <RatingNote<AppointmentRating> />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
