import { Box, Typography } from "@mui/material";

interface LegendItemProps {
  color: string;
  text: string;
}

function LegendItem({ color, text }: LegendItemProps) {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box sx={{ backgroundColor: color, height: 16, width: 25 }} />
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
}

export default LegendItem;
