import { EventClickArg } from "@fullcalendar/core";
import { Confirm, useDelete, useNotify, useRefresh } from "react-admin";
import React from "react";
import { Stack, Typography } from "@mui/material";
import { ArrowRightAltOutlined } from "@mui/icons-material";
import { LawyerAvailability } from "../api";
import { LONG_MONTH_DATETIME_WITHOUT_SECONDS } from "../../../constants/date_formats";

type MoveConsultationDialogPros = {
  eventClick: EventClickArg | null;
  onClose: () => void;
};

export default function DeleteAvailabilityDialog({ eventClick, onClose }: MoveConsultationDialogPros) {
  const notify = useNotify();
  const refresh = useRefresh();
  const [deleteOne, { isLoading }] = useDelete<LawyerAvailability>();

  const confirmEventDelete = async () => {
    return deleteOne(
      "staff/availabilities",
      {
        id: eventClick?.event.extendedProps.availability_id,
      },
      {
        mutationMode: "pessimistic",
        onSuccess: () => {
          refresh();
          onClose();
        },
        onError: (error: unknown) => {
          notify((error as Error).message, { type: "error" });
          onClose();
        },
      },
    );
  };

  return (
    <Confirm
      isOpen={eventClick !== null}
      loading={isLoading}
      title="lawyer.availability.delete.confirmTitle"
      translateOptions={{ title: eventClick?.event.title }}
      content={
        <Stack direction="row" spacing={1} alignItems="center" mt={1}>
          <Typography variant="body1">
            {eventClick?.event.start!.toLocaleString(undefined, LONG_MONTH_DATETIME_WITHOUT_SECONDS) ?? ""}
          </Typography>
          <ArrowRightAltOutlined fontSize="medium" />
          <Typography variant="body1">
            {eventClick?.event.end!.toLocaleString(undefined, LONG_MONTH_DATETIME_WITHOUT_SECONDS) ?? ""}
          </Typography>
        </Stack>
      }
      onConfirm={confirmEventDelete}
      onClose={onClose}
      confirmColor="warning"
    />
  );
}
