import {
  Edit,
  SimpleForm,
  useTranslate,
  WithRecord,
  useRecordContext,
  Labeled,
  ReferenceField,
  ChipField,
  FunctionField,
  Link,
  useCreatePath,
} from "react-admin";
import { ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { Question } from "../api";
import QuestionAside from "./QuestionAside";
import { detailColorMapping, lawyerAnswerSxProps } from "./themes";
import LawyerAnswer from "./components/LawyerAnswer";
import LawyerAdditionalAnswer from "./components/LawyerAdditionalAnswer";
import MemberAdditionalRequest from "./components/MemberAdditionalRequest";
import MemberQuestion from "./components/MemberQuestion";
import ArchivedBlock from "./components/ArchivedBlock";
import MemberAnswerRating from "./components/MemberAnswerRating";
import ColoredChip from "../../../components/ColoredChip";
import QuestionEditToolbar from "./components/QuestionEditToolbar";

function QuestionTitle() {
  const record = useRecordContext<Question>();
  const translate = useTranslate();

  if (!record) return null;
  return <span>{translate("question.edit.title", { questionId: record.id })}</span>;
}

function QuestionTag(): ReactNode {
  const createPath = useCreatePath();
  const translate = useTranslate();

  return (
    <FunctionField<Question>
      render={(question) => (
        <ReferenceField source="tag_id" reference="staff/tags" emptyText="question.edit.tag.missing">
          {question.has_conflicting_tag ? (
            <Link to={createPath({ resource: "staff/customers", type: "edit", id: question.customer_id! })}>
              <ChipField
                source="name"
                color="error"
                icon={<ErrorOutlinedIcon />}
                title={translate("question.edit.tag.badConfiguration")}
              />
            </Link>
          ) : (
            <ChipField source="name" />
          )}
        </ReferenceField>
      )}
    />
  );
}

export default function QuestionEdit(): ReactNode {
  const translate = useTranslate();

  return (
    <Edit mutationMode="pessimistic" aside={<QuestionAside />} title={<QuestionTitle />}>
      <SimpleForm toolbar={<QuestionEditToolbar />}>
        <Stack sx={{ width: 1 }}>
          <Stack direction="row" spacing={2}>
            <Typography component="h2" variant="h5">
              {translate("question.edit.question.title")}
            </Typography>
            <WithRecord<Question>
              render={(question) => (
                <ColoredChip
                  value={question.unified_status}
                  colorMapping={detailColorMapping}
                  labelPrefix="question.edit.status"
                />
              )}
            />
          </Stack>
          <Stack direction="row" spacing={4} marginTop={2}>
            <Labeled>
              <ReferenceField
                label="question.edit.law_domain.label"
                source="law_domain_id"
                reference="staff/law-domains"
                emptyText="question.edit.law_domain.missing"
              >
                <ChipField source="name" />
              </ReferenceField>
            </Labeled>
            <Labeled label="Tags">
              <QuestionTag />
            </Labeled>
            <ArchivedBlock />
          </Stack>
          <MemberQuestion />
          <ReferenceField reference="staff/answers" source="finalized_answer_id">
            <LawyerAnswer contentSx={lawyerAnswerSxProps} />
          </ReferenceField>
          <ReferenceField reference="staff/answers" source="finalized_answer_id">
            <ReferenceField reference="staff/additional-requests" source="additional_request_id">
              <MemberAdditionalRequest />
              <LawyerAdditionalAnswer />
            </ReferenceField>
          </ReferenceField>
          <ReferenceField label="Note membre" source="rating_id" reference="staff/answer-ratings">
            <MemberAnswerRating />
          </ReferenceField>
        </Stack>
      </SimpleForm>
    </Edit>
  );
}
