import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { ReactNode } from "react";
import ProductForm from "./ProductForm";

export default function EditProductDialogButton(): ReactNode {
  return (
    <EditInDialogButton mutationMode="pessimistic" fullWidth maxWidth="md" inline>
      <ProductForm />
    </EditInDialogButton>
  );
}
