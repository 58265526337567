import React, { ReactNode } from "react";
import { maxLength, required, SelectInput, TextInput } from "react-admin";
import { Stack } from "@mui/material";

export default function CustomerBaseInputs(): ReactNode {
  return (
    <Stack sx={{ maxWidth: "sm", width: "100%" }}>
      <TextInput validate={[required(), maxLength(100)]} source="name" label="customer.edit.name.label" />
      <TextInput
        validate={[required(), maxLength(20)]}
        source="code"
        label="customer.edit.code.label"
        parse={(value) => (value ? value.toUpperCase() : "")}
      />
      <SelectInput
        validate={required()}
        source="type"
        choices={[
          { id: "distributor", name: "customer.list.column.type.distributor" },
          { id: "employer", name: "customer.list.column.type.employer" },
        ]}
        label="customer.edit.type.label"
      />
    </Stack>
  );
}
