import { required, SelectInput, useDataProvider } from "react-admin";
import { Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ActionDialogButton from "../../../../components/ActionDialogButton";
import { Question, QuestionArchiveInput, ArchivedReasons } from "../../api";
import { TagInput } from "../components/TagInput";
import { EkieDataProvider } from "../../../../providers/dataProvider";

const archivedReasons: { id: ArchivedReasons; name: string }[] = [
  { id: "other", name: "question.edit.archivedReason.other" },
  { id: "excluded_domain", name: "question.edit.archivedReason.excluded_domain" },
  { id: "no_question_asked", name: "question.edit.archivedReason.no_question_asked" },
  { id: "too_many_questions_asked", name: "question.edit.archivedReason.too_many_questions_asked" },
];

const tagValidation = (value: number | null, allValues: Question) => {
  if (allValues.archived_reason === "excluded_domain" && value === null) {
    return "question.edit.archive.dialog.error.missingTag";
  }
  return undefined;
};

export default function ArchiveDialogButton() {
  const dataProvider = useDataProvider<EkieDataProvider>();
  return (
    <ActionDialogButton<Question, QuestionArchiveInput>
      dialogTitle="question.edit.archive.dialog.title"
      buttonProps={{
        label: "question.edit.archive.button",
        color: "error",
        startIcon: <DeleteIcon />,
        size: "medium",
      }}
      dataProviderAction={dataProvider.archiveQuestion}
      paramTransformer={(question) => {
        return { question_id: question.id, reason: question.archived_reason!, tag_id: question.tag_id };
      }}
    >
      <Grid sx={{ width: 1 }}>
        <SelectInput
          source="archived_reason"
          label="question.edit.archivedReason.label"
          choices={archivedReasons}
          validate={required()}
        />

        <TagInput source="tag_id" validate={tagValidation} />
      </Grid>
    </ActionDialogButton>
  );
}
