import { AppointmentCancelReason } from "../lawyer/api";
import { axiosInstance } from "../../providers/api";

export const consultationTypes = [
  "status_pending",
  "status_completed",
  "status_scheduled",
  "status_to_reschedule",
  "status_cancelled",
] as const;
export type ConsultationType = (typeof consultationTypes)[number];

export interface Consultation {
  id: number;
  status: ConsultationType;
  created_at: string;
  member_id: number;
  law_domain_id: string | null;
  lawyer_id: number | null;
  rating_id: number | null;
  appointment_start_at: string | null;
  can_cancel_appointment_or_consultation: boolean;
  can_transition_to_completed: boolean;
}

export type AppointmentRatingReason = "no_show" | "late" | "other";

export interface AppointmentRating {
  id: number;
  appointment_id: number;
  created_at: string;
  reason: AppointmentRatingReason | null;
  note: number;
}

interface ConsultationInput {
  consultation_id: number;
}

export interface ConsultationCancelInput extends ConsultationInput {
  incident_type: AppointmentCancelReason;
  to_reschedule: boolean;
}

export const consultationProviderMethods = {
  async cancelConsultation({ consultation_id, incident_type, to_reschedule }: ConsultationCancelInput) {
    if (to_reschedule) {
      return axiosInstance.post(`/staff/consultations/${consultation_id}/to_reschedule`, {
        incident_type,
      });
    }
    return axiosInstance.post(`/staff/consultations/${consultation_id}/cancel`, {
      incident_type,
    });
  },
  async completeConsultation({ consultation_id }: ConsultationInput) {
    return axiosInstance.post(`/staff/consultations/${consultation_id}/complete`);
  },
};

export type ConsultationDataProvider = typeof consultationProviderMethods;
