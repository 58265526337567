import { AutocompleteInput, List, ReferenceInput, useLocaleState, useRedirect, useTranslate } from "react-admin";
import { Calendar } from "@react-admin/ra-calendar";
import React, { useState } from "react";
import frLocale from "@fullcalendar/core/locales/fr";
import { getFilterValuesFromInterval } from "@react-admin/ra-calendar/src/getFilterValuesFromInterval";
import { EventChangeArg, EventClickArg } from "@fullcalendar/core";
import { EventImpl } from "@fullcalendar/core/internal";
import { Stack } from "@mui/material";
import MoveConsultationDialog from "./MoveConsultationDialog";
import DeleteAvailabilityDialog from "./DeleteAvailabilityDialog";
import {
  APPOINTMENT_CLOSED_COLOR,
  APPOINTMENT_PHONE_COLOR,
  APPOINTMENT_VIDEO_COLOR,
  AVAILABILITY_COLOR,
} from "./colors";
import { AppointmentExtendedProps, AvailabilityExtendedProps } from "../api";
import LegendItem from "../../../components/calendar/LegendItem";

export default function LawyerCalendar(): React.ReactElement {
  const [locale] = useLocaleState();
  const translate = useTranslate();
  const [eventChange, setEventChange] = useState<EventChangeArg | null>(null);
  const [eventClickAvailability, setEventClickAvailability] = useState<EventClickArg | null>(null);
  const redirect = useRedirect();

  const onClick = (event: EventClickArg) => {
    const extendedProp = event.event.extendedProps as AvailabilityExtendedProps | AppointmentExtendedProps;
    if (extendedProp.event_type === "availability") {
      setEventClickAvailability(event);
    } else if (extendedProp.event_type === "appointment") {
      redirect("edit", "staff/consultations", extendedProp.consultation_id);
    }
  };

  return (
    <List
      title="calendar"
      filterDefaultValues={getFilterValuesFromInterval()}
      pagination={false}
      filters={[
        <ReferenceInput
          source="lawyer_id"
          reference="staff/lawyers"
          alwaysOn
          filter={{ status: ["active", "in_test"] }}
          perPage={20}
        >
          <AutocompleteInput label="lawyer" sx={{ minWidth: 250 }} />
        </ReferenceInput>,
      ]}
      exporter={false}
    >
      <Stack direction="row" spacing={4} m={2}>
        <LegendItem color={AVAILABILITY_COLOR} text={translate("lawyer.calendar.legend.availability")} />
        <LegendItem color={APPOINTMENT_PHONE_COLOR} text={translate("lawyer.calendar.legend.appointment_phone")} />
        <LegendItem color={APPOINTMENT_VIDEO_COLOR} text={translate("lawyer.calendar.legend.appointment_video")} />
        <LegendItem color={APPOINTMENT_CLOSED_COLOR} text={translate("lawyer.calendar.legend.appointment_closed")} />
      </Stack>
      <Calendar
        themeSystem="standard"
        selectOverlap={(stillEvent: EventImpl, _: EventImpl | null) => stillEvent.groupId !== "availability"}
        locales={[frLocale]}
        locale={locale}
        contentHeight="auto"
        slotMinTime="08:00"
        slotMaxTime="22:00"
        weekends={false}
        // @ts-expect-error TS2322
        allDaySlot={false}
        initialView="timeGridWeek"
        nowIndicator
        eventClick={onClick}
        eventChange={setEventChange}
        selectable={false}
      />
      <MoveConsultationDialog eventChange={eventChange} onClose={() => setEventChange(null)} />
      <DeleteAvailabilityDialog eventClick={eventClickAvailability} onClose={() => setEventClickAvailability(null)} />
    </List>
  );
}
