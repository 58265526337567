import {
  AutocompleteArrayInput,
  CheckboxGroupInput,
  ChipField,
  Datagrid,
  FunctionField,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  SingleFieldList,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { Chip } from "@mui/material";
import { Lawyer } from "./api";

export default function LawyerList() {
  const translate = useTranslate();

  return (
    <List
      perPage={25}
      title={translate("lawyer.list.title")}
      exporter={false}
      storeKey={false}
      filterDefaultValues={{ status: ["active", "in_test"] }}
      filters={[
        <TextInput
          key={0}
          label={translate("lawyer.list.filter.lastName.label")}
          source="last_name"
          resettable
          alwaysOn
        />,
        <TextInput
          resettable
          key={1}
          label={translate("lawyer.list.filter.firstName.label")}
          source="first_name"
          alwaysOn
        />,
        <TextInput key={2} label={translate("lawyer.list.filter.email.label")} source="email" resettable alwaysOn />,
        <ReferenceArrayInput key={3} alwaysOn source="law_domain_id" reference="staff/law-domains" perPage={100}>
          <AutocompleteArrayInput
            optionText="name"
            label={translate("lawyer.list.filter.law.label")}
            sx={{ minWidth: 280 }}
          />
        </ReferenceArrayInput>,
        <TextInput
          resettable
          key={5}
          label={translate("lawyer.list.filter.id.label")}
          source="id"
          sx={{ width: 115 }}
        />,
        <CheckboxGroupInput
          key={4}
          alwaysOn
          label={translate("lawyer.list.filter.status.label")}
          source="status"
          choices={[
            { name: translate("lawyer.list.column.status.active"), id: "active" },
            { name: translate("lawyer.list.column.status.inactive"), id: "inactive" },
            { name: translate("lawyer.list.column.status.in_test"), id: "in_test" },
          ]}
        />,
      ]}
    >
      <Datagrid size="small" bulkActionButtons={false} rowClick="edit" sx={{ marginTop: 3 }}>
        <TextField source="full_name" label={translate("lawyer.list.column.fullName.title")} sortable={false} />
        <TextField label={translate("lawyer.list.column.email.title")} source="email" sortable={false} />
        <ReferenceArrayField
          reference="staff/law-domains"
          source="law_domain_ids"
          label={translate("lawyer.edit.form.law.label")}
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField<Lawyer>
          label={translate("lawyer.list.column.status.title")}
          render={(record) => <Chip size="small" label={translate(`lawyer.list.column.status.${record.status}`)} />}
        />
      </Datagrid>
    </List>
  );
}
