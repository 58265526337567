import { ReactElement } from "react";
import { CalendarMonth as CalendarMonthIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { getFilterValuesFromInterval } from "@react-admin/ra-calendar/src/getFilterValuesFromInterval";
import { Button } from "react-admin";

interface LawyerCalendarProps {
  lawyer_id: number;
}

export default function LawyerCalendarButton({ lawyer_id }: LawyerCalendarProps): ReactElement {
  return (
    <Button
      startIcon={<CalendarMonthIcon />}
      component={Link}
      to={{
        pathname: "/lawyer-events",
        search: `filter=${JSON.stringify(getFilterValuesFromInterval(undefined, { lawyer_id }))}`,
      }}
      label="lawyer.edit.actions.calendar.label"
    />
  );
}
