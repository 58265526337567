import {
  ChipField,
  Datagrid,
  Edit,
  FunctionField,
  Labeled,
  ReferenceField,
  ReferenceManyField,
  SimpleForm,
  useRecordContext,
  useTranslate,
  WithRecord,
} from "react-admin";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Consultation } from "./api";
import ColoredChip from "../../components/ColoredChip";
import { statusColorMapping } from "./themes";
import ConsultationAside from "./ConsultationAside";
import DateTimeField from "../../components/DateTimeField";
import { LawyerAppointment } from "../lawyer/api";
import AppointmentStatus from "./components/AppointmentStatus";
import LawyerCalendarButton from "../lawyer/components/LawyerCalendarButton";
import EditConsultationDialogButton from "./components/EditConsultationDialogButton";
import ConsultationEditToolbar from "./components/ConsultationEditToolbar";
import MemberAppointmentRating from "./components/MemberAppointmentRating";

function ConsultationTitle() {
  const record = useRecordContext<Consultation>();
  const translate = useTranslate();

  if (!record) return null;
  return <span>{translate("consultation.edit.title", { consultationId: record.id })}</span>;
}

function AppointmentButtons(): React.ReactElement | null {
  const record = useRecordContext<LawyerAppointment>();
  if (!record?.can_be_rescheduled) return null;

  return (
    <Stack direction="row" spacing={1} justifyContent="flex-end">
      <EditConsultationDialogButton appointment_id={record.id.toString()} title={record.title} />
      <LawyerCalendarButton lawyer_id={record.lawyer_id} />
    </Stack>
  );
}

export default function ConsultationEdit() {
  const translate = useTranslate();
  return (
    <Edit mutationMode="pessimistic" title={<ConsultationTitle />} aside={<ConsultationAside />}>
      <SimpleForm toolbar={<ConsultationEditToolbar />}>
        <Stack sx={{ width: 1 }} spacing={2}>
          <Stack direction="row" spacing={2}>
            <Typography component="h2" variant="h5">
              {translate("consultation.edit.consultation.title")}
            </Typography>
            <WithRecord<Consultation>
              render={(consultation) => (
                <ColoredChip
                  value={consultation.status}
                  colorMapping={statusColorMapping}
                  labelPrefix="consultation.list.column.status"
                />
              )}
            />
          </Stack>
          <Stack direction="row" spacing={4} marginTop={2}>
            <Labeled>
              <ReferenceField
                label="question.edit.law_domain.label"
                source="law_domain_id"
                reference="staff/law-domains"
                emptyText="question.edit.law_domain.missing"
              >
                <ChipField source="name" />
              </ReferenceField>
            </Labeled>
            <Labeled>
              <ReferenceField
                source="tag_id"
                reference="staff/tags"
                sortable={false}
                emptyText="question.edit.tag.missing"
              >
                <ChipField source="name" />
              </ReferenceField>
            </Labeled>
          </Stack>
          <MemberAppointmentRating />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            {translate("consultation.edit.appointment.title")}
          </Typography>
          <ReferenceManyField reference="staff/appointments" target="consultation_id" label="">
            <Datagrid bulkActionButtons={false} rowClick={false} hover={false}>
              <DateTimeField
                showTime
                source="start_at"
                label="consultation.edit.appointment.column.startAt.title"
                sortable={false}
              />
              <ReferenceField
                source="lawyer_id"
                reference="staff/lawyers"
                label="question.edit.assignation.column.lawyer.title"
                sortable={false}
              />
              <FunctionField<LawyerAppointment>
                label="consultation.edit.appointment.column.status.title"
                render={(appointment) => <AppointmentStatus appointment={appointment} />}
              />
              <AppointmentButtons />
            </Datagrid>
          </ReferenceManyField>
        </Stack>
      </SimpleForm>
    </Edit>
  );
}
