import { AutocompleteInput, AutocompleteInputProps, ReferenceInput, required, useTranslate } from "react-admin";

function CityInput(props: AutocompleteInputProps) {
  const translate = useTranslate();

  return (
    <ReferenceInput source="city_id" reference="staff/cities">
      <AutocompleteInput
        validate={required()}
        optionText={(city) => `${city.name} ${city.zipcode}`}
        label={translate("lawyer.edit.form.city.label")}
        {...props}
      />
    </ReferenceInput>
  );
}

export default CityInput;
