import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import {
  Button as RaButton,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { EkieDataProvider } from "../../providers/dataProvider";
import { Member } from "./api";

export default function MemberCancelButton() {
  const record = useRecordContext<Member>();
  if (!record) return null;
  const refresh = useRefresh();
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);
  const [cancelAt, setCancelAt] = React.useState<"later" | "now">(
    record.subscription_status === "active_terminated" ? "now" : "later",
  );
  const translate = useTranslate();
  const dataProvider = useDataProvider<EkieDataProvider>();
  const { mutate, isPending } = useMutation({
    mutationFn: () => dataProvider.cancelMember(record.id, cancelAt === "now" ? new Date() : undefined),
    onSuccess: () => {
      setOpen(false);
      refresh();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setOpen(false);
      if (error?.body?.message) {
        notify(error.body.message, { type: "error" });
      } else {
        notify("component.dialog.action.error", { type: "error" });
      }
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (isPending) return;
    setOpen(false);
  };

  return (
    <>
      <RaButton variant="outlined" onClick={handleClickOpen} label="member.cancel" color="error" size="medium" />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate("member.cancel.title", { name: record.full_name })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <RadioGroup
              defaultValue={cancelAt}
              name="cancel-at"
              onChange={(e) => setCancelAt(e.target.value as "later" | "now")}
            >
              <FormControlLabel value="later" control={<Radio />} label={translate("member.cancel.cancelLater")} />
              <FormControlLabel value="now" control={<Radio />} label={translate("member.cancel.cancelNow")} />
            </RadioGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={isPending} onClick={() => mutate()} autoFocus color="error">
            {translate("member.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
