import {
  ArrayInput,
  maxLength,
  minLength,
  minValue,
  NumberInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  Toolbar,
  WithRecord,
} from "react-admin";
import { Grid } from "@mui/material";
import React, { ReactNode } from "react";
import { TagArrayInput } from "../../questions/edit/components/TagInput";
import { Product } from "./api";

function ProductEditToolbar(): ReactNode {
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
}

const validateDomain = [
  required(),
  maxLength(50),
  regex(/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "resources.staff/products.fields.onboarding_domains.errorMessage"),
];
const validateOnboardingCode = [minLength(6), maxLength(100)];

export default function ProductForm(): ReactNode {
  return (
    <TabbedForm toolbar={<ProductEditToolbar />} syncWithLocation={false}>
      <TabbedForm.Tab label="resources.staff/products.tab.general.label">
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <TextInput source="name" validate={required()} />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              source="collaborator_type"
              choices={[
                { id: "cse", name: "customer.edit.section.product.column.collaborator_type.cse" },
                { id: "employee", name: "customer.edit.section.product.column.collaborator_type.employee" },
              ]}
            />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="resources.staff/products.tab.questions.label">
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <NumberInput source="max_total_questions_per_member" validate={minValue(0)} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput source="max_parallel_questions_per_member" validate={minValue(0)} />
          </Grid>
          <Grid item xs={12}>
            <TagArrayInput source="forbidden_question_tag_ids" />
          </Grid>
          <Grid item xs={12}>
            <TagArrayInput source="restricted_question_tag_ids" />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="resources.staff/products.tab.consultations.label">
        <NumberInput source="max_total_consultations_per_member" validate={minValue(0)} />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="resources.staff/products.tab.onboarding.label">
        <WithRecord<Product>
          render={(product) => {
            return product?.onboarding_email_white_list?.length > 0 ? (
              <TextInput
                disabled
                multiline
                source="onboarding_email_white_list"
                helperText="resources.staff/products.fields.onboarding_email_white_list.helper"
              />
            ) : null;
          }}
        />
        <TextInput source="onboarding_code" validate={validateOnboardingCode} defaultValue={null} />
        <ArrayInput source="onboarding_domains" defaultValue={[]}>
          <SimpleFormIterator disableReordering inline>
            <TextInput size="small" hiddenLabel source="domain" label={false} validate={validateDomain} />
          </SimpleFormIterator>
        </ArrayInput>
      </TabbedForm.Tab>
    </TabbedForm>
  );
}
