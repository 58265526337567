import { Labeled, useRecordContext, useTranslate } from "react-admin";
import { Chip, Stack } from "@mui/material";
import React from "react";
import { Question } from "../../api";
import DateTimeField from "../../../../components/DateTimeField";

export default function ArchivedBlock(): React.ReactNode {
  const translate = useTranslate();
  const record = useRecordContext<Question>();

  if (record === null || record?.archived_at === null) return null;
  return (
    <Stack direction="row" spacing={4}>
      <Labeled>
        <DateTimeField source="archived_at" sortable={false} showTime label="question.edit.archivedAt.label" />
      </Labeled>
      <Labeled>
        <Labeled label="question.edit.archivedReason.label">
          <Chip size="small" label={translate(`question.edit.archivedReason.${record!.archived_reason}`)} />
        </Labeled>
      </Labeled>
    </Stack>
  );
}
