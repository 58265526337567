import { ListButton, TopToolbar, useRecordContext } from "react-admin";
import { ReactElement } from "react";
import { Lawyer } from "../api";
import LawyerCalendarButton from "../components/LawyerCalendarButton";

export default function LawyerActionsToolbar(): ReactElement | null {
  const record = useRecordContext<Lawyer>();
  if (!record) return null;

  return (
    <TopToolbar>
      <LawyerCalendarButton lawyer_id={record.id} />
      <ListButton label="lawyer.edit.actions.list.label" />
    </TopToolbar>
  );
}
