import { Toolbar, useRecordContext } from "react-admin";
import { Stack } from "@mui/material";
import React from "react";
import { Consultation } from "../api";
import CancelDialogButton from "./CancelDialogButton";
import CompleteConsultationDialogButton from "./CompleteConsultationDialogButton";

export default function ConsultationEditToolbar() {
  const record = useRecordContext<Consultation>();
  if (!record || ![record.can_cancel_appointment_or_consultation, record.can_transition_to_completed].includes(true))
    return null;

  return (
    <Toolbar>
      <Stack spacing={2} direction="row">
        {record.can_cancel_appointment_or_consultation && <CancelDialogButton />}
        {record.can_transition_to_completed && <CompleteConsultationDialogButton />}
      </Stack>
    </Toolbar>
  );
}
