import { Alert, AlertTitle, Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

interface ErrorPayload {
  message: string;
  details: string[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isErrorPayload(obj: any): obj is ErrorPayload {
  return obj && typeof obj.message === "string" && Array.isArray(obj.details);
}

export const ErrorAlert = React.forwardRef<HTMLDivElement, ErrorPayload>(
  ({ message, details }: ErrorPayload, ref): React.ReactElement => {
    const theme = useTheme();
    if (details.length > 0) {
      return (
        <Alert ref={ref} severity="error" variant="filled">
          <AlertTitle>{message}</AlertTitle>
          <Stack direction="column" spacing={1}>
            {details.map((detail, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={idx}>{detail}</Box>
            ))}
          </Stack>
        </Alert>
      );
    }
    return (
      <Alert ref={ref} severity="error" variant="filled">
        <Typography fontWeight={theme.typography.fontWeightMedium}>{message}</Typography>
      </Alert>
    );
  },
);
