import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import { MarkdownField } from "@react-admin/ra-markdown";
import DateTimeField from "../../../../components/DateTimeField";
import { memberQuestionSxProps } from "../themes";
import RatingNote from "../../../../components/RatingNote";
import { AnswerRating } from "../../api";

export default function MemberAnswerRating(): React.ReactNode | null {
  const translate = useTranslate();
  const record = useRecordContext<AnswerRating>();

  if (record === null) return null;

  return (
    <Stack sx={{ alignItems: "flex-start", marginRight: "20%", marginTop: 2 }} spacing={1}>
      <Stack sx={memberQuestionSxProps}>
        <RatingNote<AnswerRating> sx={{ paddingTop: 2, paddingBottom: record!.comment !== null ? 0 : 2 }} />
        <MarkdownField source="comment" />
      </Stack>
      <Typography variant="body2" sx={{ color: grey[600] }} component="div">
        <Stack direction="row" spacing={0.5}>
          <span>{translate("question.edit.ratingAt")}</span>
          <DateTimeField source="created_at" showTime />
        </Stack>
      </Typography>
    </Stack>
  );
}
