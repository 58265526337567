import { Create, SimpleForm } from "react-admin";
import React from "react";
import CustomerBaseInputs from "./CustomerBaseInputs";

export default function CustomerCreate() {
  return (
    <Create redirect="show">
      <SimpleForm>
        <CustomerBaseInputs />
      </SimpleForm>
    </Create>
  );
}
