import React, { useCallback, useState } from "react";
import { Button } from "react-admin";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import EditConsultationDialog from "./EditConsultationDialog";

interface EditConsultationDialogButtonProps {
  title: string;
  appointment_id: string;
}

export default function EditConsultationDialogButton({ title, appointment_id }: EditConsultationDialogButtonProps) {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const openEditDialog = useCallback(() => setIsEditDialogOpen(true), []);
  const closeEditDialog = useCallback(() => setIsEditDialogOpen(false), []);
  return (
    <>
      <Button
        size="small"
        onClick={openEditDialog}
        label="lawyer.appointment.edit.moveButton.label"
        startIcon={<MoveDownIcon />}
      />
      <EditConsultationDialog
        isOpen={isEditDialogOpen}
        onClose={closeEditDialog}
        title={title}
        appointment_id={appointment_id}
      />
    </>
  );
}
