import React, { useState } from "react";
import {
  Datagrid,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  RowClickFunction,
  SimpleShowLayout,
  TextField,
  useTranslate,
  WithListContext,
  WithRecord,
} from "react-admin";
import { Card, CardContent, CardHeader } from "@mui/material";
import { ShowDialog } from "@react-admin/ra-form-layout";
import { Answer } from "../../api";
import DateTimeField from "../../../../components/DateTimeField";
import AnswerStatusChip from "../components/AnswerStatusChip";
import LawyerAnswer from "../components/LawyerAnswer";

function shouldDisplayAnswersCard(answers?: Answer[]): boolean {
  if (answers === undefined) return false;
  if (answers.length === 0) return false;
  if (answers.length === 1 && answers[0].status === "published") return false;
  return true;
}

export default function AnswersCard(): React.ReactElement | null {
  const translate = useTranslate();
  const [dialogOpenedAnswerId, setDialogOpenedAnswerId] = useState<number | null>(null);
  const onRowClick: RowClickFunction = (id) => {
    setDialogOpenedAnswerId(id as number);
    return false;
  };
  return (
    <ReferenceManyField target="question_id" reference="staff/answers">
      <WithListContext<Answer>
        render={({ data }) => {
          if (!shouldDisplayAnswersCard(data)) return null;
          return (
            <Card>
              <CardHeader title={translate("aside.answers.title")} titleTypographyProps={{ variant: "h6" }} />
              <CardContent>
                <Datagrid bulkActionButtons={false} size="small" rowClick={onRowClick}>
                  <DateTimeField
                    showTime
                    source="created_at"
                    label="question.edit.answer.column.date.title"
                    sortable={false}
                  />
                  <ReferenceField
                    source="lawyer_id"
                    reference="staff/lawyers"
                    label="question.edit.answer.column.lawyer.title"
                    sortable={false}
                  >
                    <TextField source="full_name" />
                  </ReferenceField>
                  <FunctionField<Answer>
                    label="question.edit.answer.column.status.title"
                    render={(answer) => <AnswerStatusChip status={answer.status} />}
                  />

                  <WithRecord<Answer>
                    render={(answer) => {
                      return (
                        <ShowDialog
                          isOpen={dialogOpenedAnswerId === answer.id}
                          close={() => setDialogOpenedAnswerId(null)}
                          fullWidth
                          maxWidth="md"
                        >
                          <SimpleShowLayout>
                            <LawyerAnswer />
                          </SimpleShowLayout>
                        </ShowDialog>
                      );
                    }}
                  />
                </Datagrid>
              </CardContent>
            </Card>
          );
        }}
      />
    </ReferenceManyField>
  );
}
