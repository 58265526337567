import { Datagrid, FunctionField, List, SelectInput, TextField, TextInput } from "react-admin";
import React from "react";
import { Customer } from "./api";
import ColoredChip from "../../components/ColoredChip";

export function CustomerList() {
  return (
    <List
      perPage={25}
      exporter={false}
      storeKey={false}
      filters={[
        <TextInput label="customer.list.filter.search.label" source="search" resettable alwaysOn />,
        <SelectInput
          label="customer.list.filter.type.label"
          source="type"
          alwaysOn
          choices={[
            { id: "distributor", name: "customer.list.column.type.distributor" },
            { id: "employer", name: "customer.list.column.type.employer" },
          ]}
        />,
      ]}
    >
      <Datagrid size="small" bulkActionButtons={false} rowClick="show" sx={{ marginTop: 3 }}>
        <TextField source="name" label="customer.list.column.name.title" sortable={false} />
        <TextField source="code" label="customer.list.column.code.title" sortable={false} />
        <FunctionField<Customer>
          render={(customer) => (
            <ColoredChip
              value={customer.type}
              labelPrefix="customer.list.column.type"
              size="small"
              colorMapping={{ distributor: "info", employer: "success" }}
            />
          )}
          label="customer.list.column.type.title"
        />
      </Datagrid>
    </List>
  );
}
