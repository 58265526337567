import { ReactNode } from "react";
import { Edit, SimpleForm, useRecordContext } from "react-admin";
import { Customer } from "./api";
import CustomerEditToolbar from "./CustomerEditToolbar";
import CustomerBaseInputs from "./CustomerBaseInputs";

function CustomerEditTitle() {
  const record = useRecordContext<Customer>();
  if (!record) return null;
  return <span>{record.name}</span>;
}

export function CustomerEdit(): ReactNode {
  return (
    <Edit mutationMode="pessimistic" title={<CustomerEditTitle />} redirect="show">
      <SimpleForm toolbar={<CustomerEditToolbar />}>
        <CustomerBaseInputs />
      </SimpleForm>
    </Edit>
  );
}
