import { Edit } from "react-admin";

import DispatchConfigForm, { ConfigByDomain } from "./DispatchConfigForm";
import { DispatchConfigResponse } from "../../../providers/api";

function flatConfigByDomainFormData(data: ConfigByDomain): { configs: DispatchConfigResponse["configs"] } {
  return {
    configs: Object.values(data).flatMap((domain) => domain.configs),
  };
}

function DispatchConfigEdit() {
  return (
    <Edit
      mutationMode="pessimistic"
      id="DISPATCH_CONFIG"
      resource="staff/dispatch-config"
      title="dispatch.edit.title"
      redirect={false}
      transform={flatConfigByDomainFormData}
    >
      <DispatchConfigForm />
    </Edit>
  );
}

export default DispatchConfigEdit;
