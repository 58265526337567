import {
  GetListParams,
  addRefreshAuthToDataProvider,
  UpdateResult,
  DataProvider,
  GetManyParams,
  GetManyResult,
  withLifecycleCallbacks,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  GetListResult,
  RaRecord,
} from "react-admin";

import { refreshAuthToken } from "./authProvider";
import { axiosInstance, fetchArrayResource, fetchPaginatedResource } from "./api";
import { MemberDataProvider, memberProviderMethods } from "../features/members/api";
import { QuestionDataProvider, questionHandlers, questionProviderMethods } from "../features/questions/api";

import { getListLawyerEvents, lawyerProviderMethods } from "../features/lawyer/api";
import { ConsultationDataProvider, consultationProviderMethods } from "../features/consultation/api";
import { productHandlers } from "../features/customer/product/api";
import { CustomerDataProvider, customerProviderMethods } from "../features/customer/api";

const MAX_PER_PAGE = 200;

const UNPAGINATED_RESOURCES = ["staff/appointments"];
const GLOBAL_RESOURCES = ["staff/dispatch-config"];

async function getOne<RecordType extends RaRecord>(
  resource: string,
  params: GetOneParams<RecordType>,
): Promise<GetOneResult<RecordType>> {
  if (GLOBAL_RESOURCES.includes(resource)) {
    const { data } = await axiosInstance.get<RecordType>(`/${resource}`);

    return { data: { ...data, id: params.id } };
  }
  const { data } = await axiosInstance.get<RecordType>(`/${resource}/${params.id}`);

  return { data };
}

export interface EkieDataProvider
  extends DataProvider,
    QuestionDataProvider,
    ConsultationDataProvider,
    MemberDataProvider,
    CustomerDataProvider {}

const dataProviderImpl: EkieDataProvider = {
  getOne,
  async getList<RecordType extends RaRecord>(
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<RecordType>> {
    if (resource === "lawyer-events") {
      if (!params.filter.lawyer_id) {
        return { data: [], total: 0 };
      }
      const events = await getListLawyerEvents(
        params.filter.lawyer_id,
        params.filter.start_gte,
        params.filter.start_lte,
      );

      return { data: events as unknown as RecordType[], total: events.length };
    }
    if (UNPAGINATED_RESOURCES.includes(resource)) {
      const items = await fetchArrayResource<RecordType>(resource, params.filter);
      return { data: items, total: items.length };
    }
    const { items: data, total } = await fetchPaginatedResource<RecordType>(
      resource,
      params.filter,
      params.pagination?.page,
      params.pagination?.perPage,
    );

    return { data, total };
  },
  async getMany<RecordType extends RaRecord>(
    resource: string,
    params: GetManyParams,
  ): Promise<GetManyResult<RecordType>> {
    if (params.ids.length > MAX_PER_PAGE) {
      throw new Error("Too many ids to fetch, unsupported in current implementation.");
    }
    const { items: data } = await fetchPaginatedResource<RecordType>(resource, { id: params.ids }, 1, MAX_PER_PAGE);
    return { data };
  },
  async update<RecordType extends RaRecord>(resource: string, params: UpdateParams): Promise<UpdateResult<RecordType>> {
    if (GLOBAL_RESOURCES.includes(resource)) {
      const { status, data } = await axiosInstance.put(`/${resource}`, params.data);

      if (status === 204) return getOne(resource, { id: params.id });

      return { data };
    }

    const { status, data } = await axiosInstance.put(`/${resource}/${params.id}`, params.data);
    if (status === 204) {
      return getOne(resource, { id: params.id });
    }

    return { data };
  },
  async create<RecordType extends RaRecord, ResultRecordType extends RaRecord>(
    resource: string,
    params: CreateParams<RecordType>,
  ): Promise<CreateResult<ResultRecordType>> {
    const url = `/${resource}`;
    const { data } = await axiosInstance.post(url, params.data);
    return { data };
  },
  async delete<RecordType extends RaRecord>(
    resource: string,
    params: DeleteParams<RecordType>,
  ): Promise<DeleteResult<RecordType>> {
    return axiosInstance.delete(`/${resource}/${params.id}`);
  },
  async deleteMany<RecordType extends RaRecord>(
    _resource: string,
    _params: DeleteManyParams<RecordType>,
  ): Promise<DeleteManyResult<RecordType>> {
    return Promise.reject(new Error("Not implemented"));
  },
  async getManyReference<RecordType extends RaRecord>(
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<RecordType>> {
    const { filter, target, id, pagination } = params;
    filter[target] = id;
    if (UNPAGINATED_RESOURCES.includes(resource)) {
      const items = await fetchArrayResource<RecordType>(resource, params.filter);
      return { data: items, total: items.length };
    }
    const { items: data, total } = await fetchPaginatedResource<RecordType>(
      resource,
      filter,
      pagination?.page,
      pagination?.perPage,
    );
    return { data, total };
  },
  async updateMany<RecordType extends RaRecord>(
    _resource: string,
    _params: UpdateManyParams,
  ): Promise<UpdateManyResult<RecordType>> {
    return Promise.reject(new Error("Not implemented"));
  },
  ...memberProviderMethods,
  ...questionProviderMethods,
  ...lawyerProviderMethods,
  ...consultationProviderMethods,
  ...customerProviderMethods,
};

const dataProvider = withLifecycleCallbacks(addRefreshAuthToDataProvider(dataProviderImpl, refreshAuthToken), [
  {
    resource: "*",
    beforeGetList: ({ filter, ...params }) => {
      const { q, ...otherFilters } = filter;
      const newFilter = q ? { search: q, ...otherFilters } : otherFilters;
      return Promise.resolve({ ...params, filter: newFilter });
    },
  },
  ...questionHandlers,
  ...productHandlers,
]);

export default dataProvider;
