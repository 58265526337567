import React from "react";
import { Labeled, ReferenceField, useRecordContext, useTranslate, WithRecord } from "react-admin";
import { Chip, Stack } from "@mui/material";
import { AppointmentRating, Consultation } from "../api";
import RatingNote from "../../../components/RatingNote";

export default function MemberAppointmentRating(): React.ReactElement | null {
  const consultation = useRecordContext<Consultation>();
  const translate = useTranslate();
  if (consultation?.rating_id === null) return null;

  return (
    <ReferenceField source="rating_id" reference="staff/appointment-ratings">
      <Stack direction="row" gap={4} marginTop={2}>
        <Labeled label="consultation.list.column.memberRating.title">
          <RatingNote<AppointmentRating> />
        </Labeled>

        <WithRecord<AppointmentRating>
          render={(rating) => {
            if (rating.reason === null) return null;
            return (
              <Labeled label="consultation.edit.ratingReason.title">
                <Chip label={translate(`consultation.edit.ratingReason.${rating.reason}`)} />
              </Labeled>
            );
          }}
        />
      </Stack>
    </ReferenceField>
  );
}
