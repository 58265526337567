import {
  AutocompleteInput,
  ReferenceInput,
  required,
  useDataProvider,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { Alert, Stack } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import React from "react";
import { TagInput } from "../components/TagInput";
import { Question, QuestionAssignInput } from "../../api";
import ActionDialogButton from "../../../../components/ActionDialogButton";
import { EkieDataProvider } from "../../../../providers/dataProvider";

export default function AssignDialogButton() {
  const dataProvider = useDataProvider<EkieDataProvider>();
  const record = useRecordContext<Question>();
  const translate = useTranslate();
  if (!record) return null;

  const actionLabel = ["pending_qualification", "archived", "awaiting_manual_check"].includes(record.unified_status)
    ? "question.edit.assign.dialog.assign"
    : "question.edit.assign.dialog.reassign";

  return (
    <ActionDialogButton<Question, QuestionAssignInput>
      dialogTitle={actionLabel}
      buttonProps={{ label: actionLabel, startIcon: <AssignmentIndIcon />, size: "medium" }}
      dataProviderAction={dataProvider.assignQuestion}
      paramTransformer={(question) => {
        return { tag_id: question.tag_id!, lawyer_id: question.assigned_lawyer_id!, question_id: question.id };
      }}
    >
      <Stack width={1}>
        {record.finalized_answer_id && (
          <Alert severity="info" sx={{ marginBottom: 2 }}>
            {translate("question.edit.assign.dialog.answeredWarning")}
          </Alert>
        )}
        <ReferenceInput
          source="assigned_lawyer_id"
          reference="staff/lawyers"
          filter={{ status: ["active", "in_test"] }}
        >
          <AutocompleteInput label="question.edit.assignedLawyer.label" validate={required()} />
        </ReferenceInput>
        <TagInput source="tag_id" validate={required()} />
      </Stack>
    </ActionDialogButton>
  );
}
