import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
  AutocompleteInput,
  AutocompleteInputProps,
  FunctionField,
  Labeled,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SingleFieldList,
  TextField,
  useRecordContext,
  WithListContext,
} from "react-admin";
import React from "react";
import { Chip } from "@mui/material";
import { Tag } from "../../api";

function OptionRenderer() {
  const record = useRecordContext<Tag>();
  if (!record) return null;
  return (
    <span>
      {record.name}
      <ReferenceField reference="staff/law-domains" source="law_domain_id">
        {" - "}
        <TextField source="name" variant="body1" sx={{ fontStyle: "italic" }} />
      </ReferenceField>
    </span>
  );
}

const commonAutocompleteProps: Partial<AutocompleteArrayInputProps<Tag>> & Partial<AutocompleteInputProps<Tag>> = {
  optionText: <OptionRenderer />,
  inputText: (choice: Tag) => choice.name,
  matchSuggestion: (filter: string, choice: Tag) => {
    return choice.name.toLowerCase().includes(filter.toLowerCase());
  },
  suggestionLimit: 100,
};

type TagInputProps = {
  source: string;
} & AutocompleteInputProps<Tag>;

export function TagInput({ source, ...autocompleteInputProps }: TagInputProps) {
  return (
    <>
      <ReferenceInput source={source} reference="staff/tags" perPage={100}>
        <AutocompleteInput<Tag> {...commonAutocompleteProps} {...autocompleteInputProps} />
      </ReferenceInput>
      <ReferenceField reference="staff/products" source="product_id">
        <ReferenceArrayField reference="staff/tags" source="forbidden_question_tag_ids">
          <WithListContext
            render={({ total }) => {
              if (total === undefined || total === 0) return null;
              return (
                <Labeled label="aside.member.customer.product.black_list_tags.label">
                  <SingleFieldList linkType={false}>
                    <FunctionField<Tag>
                      render={(tag) => <Chip color="warning" size="small" label={tag.name} variant="outlined" />}
                    />
                  </SingleFieldList>
                </Labeled>
              );
            }}
          />
        </ReferenceArrayField>
        <ReferenceArrayField reference="staff/tags" source="restricted_question_tag_ids">
          <WithListContext
            render={({ total }) => {
              if (total === undefined || total === 0) return null;
              return (
                <Labeled label="aside.member.customer.product.white_list_tags.label">
                  <SingleFieldList linkType={false}>
                    <FunctionField<Tag>
                      render={(tag) => <Chip color="success" size="small" label={tag.name} variant="outlined" />}
                    />
                  </SingleFieldList>
                </Labeled>
              );
            }}
          />
        </ReferenceArrayField>
      </ReferenceField>
    </>
  );
}

type TagArrayInputProps = {
  source: string;
} & AutocompleteArrayInputProps<Tag>;

export function TagArrayInput({ source, ...autocompleteArrayInputProps }: TagArrayInputProps) {
  return (
    <ReferenceArrayInput source={source} reference="staff/tags" perPage={100}>
      <AutocompleteArrayInput<Tag, false> {...commonAutocompleteProps} {...autocompleteArrayInputProps} />
    </ReferenceArrayInput>
  );
}
