import { useEffect } from "react";

function Login() {
  useEffect(() => {
    window.location.href = `${import.meta.env.VITE_API_BASE_URL}/auth/login/staff`;
  }, []);
  return null;
}

export default Login;
