import { Button, Confirm, useDataProvider, useNotify, useRecordContext, useRefresh } from "react-admin";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Question } from "../../api";
import { EkieDataProvider } from "../../../../providers/dataProvider";

export default function DraftValidatedAnswerDialogButton() {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext<Question>();
  const dataProvider = useDataProvider<EkieDataProvider>();
  const { mutate, isPending } = useMutation({
    mutationFn: () => dataProvider.draftValidatedAnswer({ question_id: record!.id }),
    onSuccess: refresh,
    onError: (error: Error) => notify(error.message, { type: "error" }),
  });
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  return (
    <>
      <Button
        label="question.edit.draftValidatedAnswer.button"
        onClick={handleClick}
        size="medium"
        startIcon={<EditIcon />}
      />
      <Confirm
        isOpen={open}
        loading={isPending}
        title="question.edit.draftValidatedAnswer.dialog.title"
        content="question.edit.draftValidatedAnswer.dialog.content"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
}
