import { useTranslate } from "react-admin";
import { useController } from "react-hook-form";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";

export default function PhoneInput({ name, label }: { name: string; label: string }) {
  const translate = useTranslate();
  const validate = (value?: string) => {
    if (value) return matchIsValidTel(value);
    return true;
  };
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name, defaultValue: "", rules: { validate } });

  return (
    <MuiTelInput
      {...field}
      label={translate(label)}
      error={invalid}
      helperText={invalid ? error?.message : " "}
      langOfCountryName="fr"
      preferredCountries={["FR"]}
      defaultCountry="FR"
    />
  );
}
