import { MarkdownField } from "@react-admin/ra-markdown";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReferenceField, TextField, useRecordContext, useTranslate } from "react-admin";
import React from "react";
import DateTimeField from "../../../../components/DateTimeField";
import { lawyerAnswerSxProps } from "../themes";
import { AdditionalRequest } from "../../api";

export default function LawyerAdditionalAnswer(): React.ReactNode | null {
  const translate = useTranslate();
  const record = useRecordContext<AdditionalRequest>();

  if (record === null || record?.request_answer === null) return null;

  return (
    <>
      <MarkdownField source="request_answer" sx={lawyerAnswerSxProps} />
      <Typography variant="body2" sx={{ color: grey[600] }} component="div">
        <Stack direction="row" spacing={0.5} justifyContent="flex-end">
          <span>{translate("question.edit.additionalRequestAnswerAt")}</span>
          <DateTimeField source="answered_at" showTime />
          <Typography variant="body2">{translate("question.edit.answeredBy")}</Typography>
          <ReferenceField
            source="lawyer_id"
            reference="staff/lawyers"
            label="question.edit.assignation.column.lawyer.title"
          >
            <TextField source="full_name" />
          </ReferenceField>
        </Stack>
      </Typography>
    </>
  );
}
