import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  CheckboxGroupInput,
} from "react-admin";
import React from "react";
import SubscriptionStatusChip from "./SubscriptionStatusChip";

export function MemberList() {
  return (
    <List
      perPage={25}
      exporter={false}
      storeKey={false}
      filterDefaultValues={{ subscription_status: ["new", "active", "active_terminated", "terminated"] }}
      filters={[
        <TextInput label="member.lastName" source="last_name" resettable alwaysOn />,
        <TextInput label="member.firstName" source="first_name" resettable alwaysOn />,
        <TextInput label="member.email" source="email" resettable alwaysOn />,
        <TextInput label="member.phone" source="phone" resettable alwaysOn />,
        <ReferenceInput source="customer_id" reference="staff/customers" alwaysOn>
          <AutocompleteInput label="member.customer" sx={{ minWidth: 250 }} />
        </ReferenceInput>,
        <TextInput label="member.id" source="id" resettable />,
        <CheckboxGroupInput
          alwaysOn
          label="member.status"
          source="subscription_status"
          choices={[
            { name: "member.status.active", id: "active" },
            { name: "member.status.active_terminated", id: "active_terminated" },
            { name: "member.status.terminated", id: "terminated" },
            { name: "member.status.new", id: "new" },
          ]}
        />,
      ]}
    >
      <Datagrid size="small" bulkActionButtons={false} rowClick="edit" sx={{ marginTop: 3 }}>
        <TextField source="full_name" label="member.fullName" sortable={false} />
        <TextField label="member.email" source="email" sortable={false} />
        <TextField label="member.phone" source="phone_cellphone" sortable={false} />
        <ReferenceField label="member.customer" source="customer_id" reference="staff/customers" sortable={false} />
        <SubscriptionStatusChip />
      </Datagrid>
    </List>
  );
}
