import { ReactNode } from "react";
import { useTranslate } from "react-admin";
import { Chip } from "@mui/material";
import { AnswerStatus } from "../../api";

export default function AnswerStatusChip({ status }: { status: AnswerStatus }): ReactNode {
  const translate = useTranslate();

  let color: "error" | "warning" | "success" | "default";
  switch (status) {
    case "taken_down":
      color = "error";
      break;
    case "pending_moderation":
      color = "warning";
      break;
    case "published":
      color = "success";
      break;
    default:
      color = "default";
      break;
  }
  return (
    <Chip
      label={translate(`question.answer.status.${status}`)}
      title={translate(`question.answer.status.${status}`)}
      color={color}
      size="small"
    />
  );
}
