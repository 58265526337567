import {
  Button,
  ButtonProps,
  SaveButton,
  SaveContextProvider,
  SimpleForm,
  Toolbar,
  useNotify,
  useRefresh,
} from "react-admin";
import React, { ReactNode, useCallback, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { EditDialog } from "@react-admin/ra-form-layout";
import ActionCheck from "@mui/icons-material/CheckCircle";

function ActionDialogToolbar() {
  return (
    <Toolbar sx={{ display: "flex", justifyContent: "end" }}>
      <SaveButton label="component.dialog.action.button.label" variant="text" icon={<ActionCheck />} />
    </Toolbar>
  );
}

export interface ActionDialogButtonProps<Resource, ActionPayload, ActionResponse = unknown> {
  children: ReactNode;
  dataProviderAction: (payload: ActionPayload) => Promise<ActionResponse>;
  buttonProps: Omit<ButtonProps, "onClick">;
  paramTransformer: (data: Resource) => ActionPayload;
  dialogTitle: string;
  onSuccess?: (response: ActionResponse) => void;
}

export default function ActionDialogButton<Resource, ActionPayload, ActionResponse = unknown>({
  children,
  buttonProps,
  dataProviderAction,
  paramTransformer,
  dialogTitle,
  onSuccess = undefined,
}: ActionDialogButtonProps<Resource, ActionPayload, ActionResponse>) {
  const refresh = useRefresh();
  const notify = useNotify();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const openEditDialog = useCallback(() => setIsEditDialogOpen(true), []);
  const closeEditDialog = useCallback(() => setIsEditDialogOpen(false), []);

  const { mutate, isPending } = useMutation<ActionResponse, unknown, ActionPayload>({
    mutationFn: (param: ActionPayload) => dataProviderAction(param),
    onSuccess: (response: ActionResponse) => {
      closeEditDialog();
      refresh();
      onSuccess?.(response);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      closeEditDialog();
      if (error?.body?.message) {
        notify(error.body.message, { type: "error" });
      } else {
        notify("component.dialog.action.error", { type: "error" });
      }
    },
  });
  const save = (data: Resource) => mutate(paramTransformer(data));
  return (
    <>
      <Button {...buttonProps} onClick={openEditDialog} />
      <EditDialog
        mutationMode="pessimistic"
        title={dialogTitle}
        label={dialogTitle}
        fullWidth
        maxWidth="sm"
        isOpen={isEditDialogOpen}
        open={openEditDialog}
        close={closeEditDialog}
      >
        <SaveContextProvider value={{ save, saving: isPending }}>
          <SimpleForm toolbar={<ActionDialogToolbar />}>{children}</SimpleForm>
        </SaveContextProvider>
      </EditDialog>
    </>
  );
}
