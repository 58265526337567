import { FunctionField } from "react-admin";
import React from "react";
import { Member } from "./api";
import ColoredChip from "../../components/ColoredChip";

export default function SubscriptionStatusChip() {
  return (
    <FunctionField<Member>
      label="member.status"
      render={(member) => (
        <ColoredChip
          value={member.subscription_status}
          labelPrefix="member.status"
          size="small"
          colorMapping={{ active: "info", active_terminated: "warning", terminated: "error" }}
        />
      )}
    />
  );
}
