import { ReactNode } from "react";
import { RaRecord, useRecordContext } from "react-admin";
import { Rating, RatingProps } from "@mui/material";

export default function RatingNote<T extends RaRecord & { note: number }>(
  props: Omit<RatingProps, "value" | "readonly">,
): ReactNode | null {
  const record = useRecordContext<T>();

  if (record === null) return null;

  return <Rating value={record!.note} readOnly {...props} />;
}
